import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import ApiRequest from "../../../components/src/ApiRequest";
import { FormikErrors, FormikTouched } from "formik";
import { getAuthToken, handleScrollToTop } from "../../../components/src/utils";
import { OptionListItem } from "../../email-account-registration/src/Interface.web";
import { AuthContext} from "../../user-profile-basic/src/AuthContext.web";
import { 
  BuddyEntityDetailsValues, BusinessDetailsValues, 
   EntityDetailsValues, PersonalDetailsValues,
   CityData, 
   CountryData,
} from "./Interface.web";
import { getStorageData } from "../../../framework/src/Utilities";
import i18next from 'i18next';

interface Plan {
  id: string;
  type: string;
  attributes: PlanAttributes;
}

interface PlanAttributes {
  id: number;
  name: string;
  description: string;
  price: string;
  plan_type: 'paid' | 'free'; 
  free_month: number;
  monthly_amount: number;
  yearly_amount: number;
  is_discounted: boolean;
  discount_type: string | null;
  discount_percent: number;
  sales_report: boolean;
  booking_report: boolean;
  calendar_view: boolean;
  booking_commission: boolean;
  commission_type: string | null;
  commission_percentage: number;
  commission_fixed: number;
  available_for: string; 
  coming_soon: boolean;
  active: boolean;
  currency: string;
}

// Example data structure
interface PlanResponse {
  data: Plan[];
}

export interface SubscriptionPlanNew {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    duration: string;
    price: string;
    visible: boolean;
    currency: string;
  };
}
interface SubscriptionPlan {
  id: number;
  name: string;
  price: string;
  description: string;
  currency_id: number;
  created_at: string;
  updated_at: string;
  available_for: string;
  discount_percent: number;
  plan_type: string;
  free_month: number;
  is_discounted: boolean;
  discount_type: string | null;
  price_after_discount: number;
  monthly_amount: number;
  yearly_amount: number;
  sales_report: boolean;
  booking_report: boolean;
  calendar_view: boolean;
  booking_commission: boolean;
  commission_type: string | null;
  commission_percentage: number;
  commission_fixed: number;
  benefits_description: string[];
  active: boolean;
  coming_soon: boolean;
  limit_type: string;
  limit: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  open: boolean;
  handlePasswordModalAppearance?: (open: boolean) => void;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  openModal: boolean;
  localModal: boolean;
  isEditClickd:boolean;
  current_password: string;
  new_password: string;
  confirm_password: string;
  showPasswordOld: boolean;
  showPasswordNew: boolean;
  showPasswordConfirm: boolean;
  responseStatsModal: boolean;
  isSuccessIcon: boolean;
  responseModalMsg: string,
  isEditProfile: boolean;
  isProfileDeleted: boolean;
  email: any;
  isEmailVerified: boolean;
  data: Record<string, any>;
  setErrorFun: any;
  countryOption: { label: string, value: string }[];
  userDetailsLeft: Record<string, any>;
  countdown: number;
  userDetailsRight: Record<string, any>;
  countriesData: OptionListItem[];
  carddetailsValue:any
  checksubscription:any
  otpHeaderTitle: string | null;
  otpErrorMessage: string | null;
  otp: string;
  ischecked:any;
  isOpen: boolean;
  isSubscription : any
  isSubscriptionPlan: SubscriptionPlan;
  openStatusModal: boolean;
  modalMsg: string;
  tab: string;
  initialLoad: boolean;
  personalDetailsValues: PersonalDetailsValues;
  entityDetailsValues: EntityDetailsValues;
  businessDetailsValues: BusinessDetailsValues;
  buddyEntityDetailsValues: BuddyEntityDetailsValues;
  loading : boolean
  countryList: CountryData[];
  cityList: CityData[],
  isEmailVarified: boolean;
  isPhoneVarified: boolean;
  errorMsg: string;
  whichFieldVarifying: string;
  openVerifyModal: boolean;
  isResendClick: boolean;
  modalForEmail:boolean;
  enableResendCode:boolean;
  otpErrorMsg:string
  phoneErrorMsg:string
  emailErrorMsg :string
  fromWichButtonSendingOtp: string;
  resetTimer:string
  mobile: string;
  timer:{
    minutes: string;
    seconds: string;
  },
  account_type :  string,
  responseMsg: string;
  responseModal: boolean;
  perfectPlanModal: boolean;
  errorMessage: string;
  cardNumber: string;
  cardHolderName: string;
  expiration: string;
  securityCode: string;
  paymentToken: string;
  linkCardOpen : boolean;
  pickPlanData: Plan[],
  checkCardInformation : boolean
  subScriptionPlan:SubscriptionPlanNew[];
  showOnclickMsg: boolean,
  subscriptionMsg:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdChangePassword: string = ""
  getCountryListApiCallId: any;
  sendVerifyEmailApiCallId: any;
  updateAutoRenewalapiCallId : any;
  updateCarddetailsapiCallId :any
  apiCallIdGetCityList:any
  apiCallIdGetCountryList:any;
  apiCallIdTravelAgencyEdit: string = '';
  apiCallIdVerifyEmailOrSmsOtp:string ='';
  apiCallIdSendEmailOrSmsOtp:string='';
  getpickPlanApiCallId : string = "";
  apiCallIdSubscriptionPlan:string='';

  emailToken: string = '';
  timer: any;
  static contextType = AuthContext
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openModal: props.open,
      isEditClickd: false,
      localModal: props.open,
      current_password: '',
      new_password: '',
      confirm_password: '',
      showPasswordNew: false,
      showPasswordOld: false,
      showPasswordConfirm: false,
      responseStatsModal: false,
      isSuccessIcon: false,
      responseModalMsg: '',
      isEditProfile: false,
      isProfileDeleted: false,
      email: "",
      isEmailVerified: true,
      data: {},
      tab: '0',
      setErrorFun: null,
      countryOption: [],
      countdown: 60,
      openStatusModal: false,
      otpHeaderTitle: null,
      otpErrorMessage: null,
      isOpen: false,
      modalMsg:'',
      otp: "",
      ischecked : false,
      userDetailsLeft: {
        'First Name': '',
        'Last Name': '',
        'Email Address': '',
        'Mobile Number': '',
        'Facebook': '',
        'Instagram': '',
        'WhatsApp': '',
      },
      userDetailsRight: {
        'Last Name': '',
        'Mobile Number': '',
        'Gender': '',
        'Country of Residence': 'a'
      },
      countriesData: [],
      carddetailsValue : {},
      checksubscription :null,
      initialLoad: false,
      isSubscription:this.context?.currentUser?.subscription_billing,
      isSubscriptionPlan: this.context?.currentUser?.subscription_plan,
      entityDetailsValues: {
        agency_display_name: '',
        agency_logo: null,
        about_me: '',
        certificatePdf: { name: '' },
        vat_number: '',
        company_legal_name: '',
        id_number: '',
        commercialPdf: null,
        permitPdf: { name: '' },
       
      },
      personalDetailsValues: {
      
        email: '',
        phoneNumber: '',
        first_name: '',
        lname: '',
        onlyPhone: '',
        instagram_link: '',
        website_link: '',
        password: '',
        facebook_link: '',
        service_provider_type: '',
      },
      
      buddyEntityDetailsValues: {
        buddy_profile_picture: null,
        about_me:'',
        id_number: '',
        vat_number: '',
        buddy_name: '',
        tourismPdf: null,
        permitPdf: null,
        commercialPdf: null,
      },
      businessDetailsValues: {
        accountNumber: '',
        iban: '',
        bankName: '',
        accountName: '',
        streetName: '',
        country: null,
        zipCode: '',
        agreeTermsAndCondition: false,
        city: null,
      },
      countryList: [],
      cityList: [],
      loading: false,
      isEmailVarified: false,
      isPhoneVarified: false,
      errorMsg: '',
      whichFieldVarifying: '',
      openVerifyModal: false,
      isResendClick: false,
      modalForEmail:false,
      enableResendCode: false,
      otpErrorMsg:'',
      phoneErrorMsg:'',
      emailErrorMsg:'',
      fromWichButtonSendingOtp:'',
      resetTimer: '',
      mobile :'',
      timer: {
        minutes: '1',
        seconds: '00'
      },
    

      account_type :'',
      responseModal: false,
      responseMsg: "",
      perfectPlanModal: false,
      errorMessage: "",
      cardNumber: "",
      cardHolderName: "",
      expiration: "",
      securityCode: "",
      paymentToken: "",
      linkCardOpen: false,
      pickPlanData: [],
      checkCardInformation : false,
      subScriptionPlan: [],
      showOnclickMsg:false,
      subscriptionMsg : ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallIds1 = {
      [this.apiCallIdChangePassword]: this.changePasswordResponse,
       [this.getCountryListApiCallId] : this.getCountryListApiResponse,
       [this.sendVerifyEmailApiCallId] : this.handleSendEmailRespOTP,
       [this.updateAutoRenewalapiCallId]: this.handleAutoRenewalResponse,
       [this.updateCarddetailsapiCallId]: this.handleUpdateCardResponse ,
       [this.apiCallIdGetCityList] :this.getCityListApiResponse,
       [this.apiCallIdTravelAgencyEdit]: this.handleTravelAgencyEditResponse,
       [this.getpickPlanApiCallId] : this.getpickPlanApiSucessCallBack,
       [this.apiCallIdSubscriptionPlan]: this.subscriptionPlanListResponse,

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId1 = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson1 = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse1 = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId1 != null && apiRequestCallIds1[apiRequestCallId1]) {
      apiRequestCallIds1[apiRequestCallId1](responseJson1, errorResponse1);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };


  // Customizable Area Start

  // Request Functions
  changePasswordAPIRequest = async (values: any) => {
    const { current_password, new_password } = values;
    const authToken = await getAuthToken();
    const token = authToken || localStorage.getItem("authToken");

    const header = {
      Authorization: `Bearer ${token}`,
    };

    const formData = new FormData();
    // Helper function to append a field and value to formData if the value exists
    const appendFieldIfValueExistss = (field: string, value: any) => {
      if (value) {
        formData.append(field, value);
      }
    };

    
    appendFieldIfValueExistss('password', current_password);
    appendFieldIfValueExistss('new_password', new_password);


    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.changePasswordEndpoint,
      payload: formData,
      method: "PUT",
    });
    this.apiCallIdChangePassword = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  updateAutoRenewalApiRequest = async (values : any)=>{
     const authToken = await getAuthToken();
     const account_id = this.context?.currentUser?.subscription_billing?.id 
     const header = {
      Authorization: `Bearer ${authToken}`,
     }
     const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.automaticRenewalEndpointFirst+account_id+configJSON.automaticRenewalEndpointSec,
      payload: account_id,
      method: "PATCH",
    });
    this.updateAutoRenewalapiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ischecked:!this.state?.ischecked})
  }

 
  updateCardDetailsApiRequest = async (values: any) => {

    const formData = new FormData();
    // Helper function to append a field and value to formData if the value exists
    const appendFieldIfValueExists = (field: string, value: any) => {
        if (value || value ===0) {
            formData.append(field, value);
        }
    };

    appendFieldIfValueExists('card_detail[card_number]', values.card_detail.card_number);
    appendFieldIfValueExists('card_detail[cardholder_name]', values.card_detail.cardholder_name);
    appendFieldIfValueExists('card_detail[expiration_date]', values.card_detail.expiration_Date);
    appendFieldIfValueExists('card_detail[cvv]',values.card_detail.cvv);

    const authToken = await getAuthToken();
    const cardId = this.context?.currentUser?.card_details[0]?.id;
    const header = {
        Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = ApiRequest({
        header: header,
        endPoint: configJSON.updatecardDetailsEndPoint + cardId,
        payload:formData,
        method: "PATCH",
    });
    this.updateCarddetailsapiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({isEditClickd : false})
}



getCityListRequest = (country: string) => {
    
  const countryId = country
  ? (this.state.countryList.find((countryData: CountryData) => countryData?.name === country)?.id || null)
  : null;

  const header = {
    "Content-Type": configJSON.validationApiContentType,
};
const requestMessage = ApiRequest({
    header: header,
    endPoint: `account_block/country/${countryId}/cities`,
    method: "GET",
});
this.apiCallIdGetCityList = requestMessage.messageId;
runEngine.sendMessage(requestMessage.id, requestMessage);
} 
  handleEditClick = () =>{
    this.setState({isEditClickd : !this.state.isEditClickd})
  }
  handleClose = ()=>{
    this.setState({isEditClickd:false})
  }

  // Response Functions
  changePasswordResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson.status === 500) {
      this.setState({
        localModal: false,
        responseStatsModal: true,
        isSuccessIcon: false,
        responseModalMsg: 'Sorry, something went wrong!'
      })
      return;
    }

    if (responseJson && responseJson?.errors) {
      // Check if the error is due to the new password being the same as the old password
      const newPasswordError = responseJson.errors.find(
        (error: any) => error.account?.new_password
      );
      if (newPasswordError) {
        const msg = newPasswordError.account.new_password[0];
        this.setState({
          localModal: false,
          responseStatsModal: true,
          isSuccessIcon: false,
          responseModalMsg: msg,
        });
        return;
      }

      // Check if the error is due to invalid credentials
      const invalidCredentialsError = responseJson.errors.find(
        (error: any) => error.account?.base && error.account.base[0] === "Invalid credentials"
      );
      if (invalidCredentialsError) {
        this.setState({
          localModal: false,
          responseStatsModal: true,
          isSuccessIcon: false,
          responseModalMsg: 'Invalid credentials.',
        });
        return;
      }
    }

    // Check if there are success messages
    if (responseJson.messages && responseJson.messages.length > 0) {
      const msg = responseJson.messages[0].account;
      this.setState({
        localModal: false,
        responseStatsModal: true,
        isSuccessIcon: true,
        responseModalMsg: msg,
      });
      return;
    }

    // If no specific error or success cases match, show a generic error message
    this.setState({
      localModal: false,
      responseStatsModal: true,
      isSuccessIcon: false,
      responseModalMsg: 'Sorry, something went wrong!',
    });
  }


  // internal functions
  handleOpenMainModal = () => {
    this.setState({
      localModal: true,
    })
  }

  subscriptionPlan = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SubscriptionPlan"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  handleCloseAllModal = () => {
    this.setState({
      localModal: false,
      openModal: false,
    }, () => this.props.handlePasswordModalAppearance?.(false))
  }

  handleShowPasswordOld = () => {
    this.setState({
      showPasswordOld: !this.state.showPasswordOld
    })
  }

  handleShowPasswordNew = () => {
    this.setState({
      showPasswordNew: !this.state.showPasswordNew
    })
  }

  handleShowPasswordConfirm = () => {
    this.setState({
      showPasswordConfirm: !this.state.showPasswordConfirm
    })
  }

  handleChange = (value: string) => {
    if (value !== this.state.tab) {
      this.setState({ tab: value });
    }
  };


  closeResponseModal = () => {
    this.setState({
      responseStatsModal: false,
      openModal: false,
    }, async () => {
      const user_type = await getStorageData("account_type");
      const isCustomer = user_type === "customer";
      this.props.handlePasswordModalAppearance?.(false);
      
      // Check if the user is not already on the desired page
      const targetUrl = isCustomer ? "/LandingPage" : "/Dashboard";
      if (window.location.pathname !== targetUrl) {
        // Navigate only if the user is not already on the desired page
        window.location.href = targetUrl;
      }
    })
  }

  handleToggleEditBtn = () => {
    this.setState({
      isEditProfile: !this.state.isEditProfile
    })
    handleScrollToTop()
  }

  handleProfileUpdate = (values: any, setFieldError: (field: string, message: string | undefined) => void) => {

  }

  deleteProfile = (setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>) => {
    this.setState({ isProfileDeleted: true })
    setFieldValue('profile_image', null);
  }

  handleImageUpload = (e: any, setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFieldValue('profile_image', selectedFile);
      this.setState({ isProfileDeleted: false })
    }
  }

  handleEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>,
    touched: FormikTouched<any>
  ) => {
    setFieldValue('email', e.target.value)
    const { target: { value = '' } = {} } = e
    touched.email = true;
    this.setState({ email: value })
     if (this.state.isEmailVerified) {
       this.setState({isEmailVerified: false})
    }
    else if (value === this.state.data.email) {
      this.setState({isEmailVerified: true})
   }
  }

  handleEmailVerfiy = (email: string, error: any, setFieldError: (field: string, message: string | undefined) => void) => {
    if (email && (error.email === "* Please verify email address" || !error.email )) {
      this.handleVerifyApiEmail(email)
    }
    this.setState({ setErrorFun: setFieldError })
  }



  handleSendEmailRespOTP(responseJson: any, errorReponse: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      if (this.state.otpHeaderTitle) {
        this.setState({
          otpHeaderTitle: "We sent you the OTP to your email address",
          countdown: 60, otpErrorMessage: null, otp: "",
        });
        this.startCountdown();
        this.emailToken = responseJson.meta.token
      } else {
        this.openCloseModal()
        this.startCountdown()
        this.emailToken = responseJson.meta.token
        this.setState({ otpHeaderTitle: `Verification code sent to you on ${this.state.email}` })
      }
    } else if (responseJson && responseJson.errors && responseJson.errors.length) {
      this.state.setErrorFun('email', `* ${responseJson.errors[0].otp}`)
    }
  }

  handleAutoRenewalResponse(responseJson: any, errorReponse: any){
    if(responseJson){
    }

  }

  handleUpdateCardResponse(responseJson: any, errorReponse: any){
    if(responseJson){
      this.setState({isEditClickd:false})
    }
      
  }
  getCountryListApiResponse = (responseJson: any, errorReponse: any) => {
    const countryDataArray = responseJson?.country_data.data;
    if (countryDataArray) {
      const countryList: CountryData[] = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          attributes.id = country.id;
          return attributes;
        }
      );
  
      this.setState({
        countryList: countryList,
      });
    }
  }

  openCloseModal = () => {
    if (this.state.isOpen) {
      clearInterval(this.timer as number | undefined)
      this.setState({ countdown: 60, otpHeaderTitle: null, otp: '' })
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  closeStatusModal = () => {
    this.setState({
      openStatusModal: false
    })
  }

  startCountdown = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => ({ countdown: prevState.countdown - 1 }), () => {
        if (this.state.countdown === 0) {
          clearInterval(this.timer as number | undefined);
          if (this.state.otp.length === 0) {
            this.setState({ otpHeaderTitle: "Please enter the OTP you received, if not please resend" });
          }
        }
      })
    }, 1000);
  }

  getCountryList = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "Accept-Language": 'en'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryListEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  
   /* istanbul ignore next */
   setProfileData = ()=>{
    const data  = this.context?.currentUser;
    if (data && data.attributes) {
      const attributes = data.attributes;
      const personalDetailsValues = {
        first_name: attributes.first_name ,
        lname: attributes.last_name ,
        email: attributes.email ,
        phoneNumber: attributes.phone_number ,
        onlyPhone: '',
        password: '',
        facebook_link: attributes.facebook_link ,
        instagram_link: attributes.instagram_link ,
        website_link: attributes.website_link ,
        service_provider_type: attributes.account_type,
      };
  
      const entityDetailAttributes = attributes.entity_detail?.data?.attributes || {};
      const entityDetailsValues = {
        agency_logo: entityDetailAttributes.agency_logo ,
        agency_display_name: entityDetailAttributes.display_name ,
        about_me: entityDetailAttributes.about_me ,
        company_legal_name: entityDetailAttributes.company_legal_name ,
        id_number: entityDetailAttributes.id_number ,
        commercialPdf: entityDetailAttributes.commerical_registration_document,
        permitPdf: entityDetailAttributes.permit_document || { name: '' },
        certificatePdf: entityDetailAttributes.certificate_file || { name: '' },
        vat_number: entityDetailAttributes.vat_number 
      };
  
      const businessDetailAttributes = attributes.business_detail?.data?.attributes || {};
      const businessDetailsValues = {
        bankName: businessDetailAttributes.bank_name ,
        accountName: businessDetailAttributes.account_name ,
        accountNumber: businessDetailAttributes.account_number ,
        iban: businessDetailAttributes.iban ,
        streetName: businessDetailAttributes.street_name ,
        country: this.context?.currentUser?.country?.data?.attributes?.name, 
        city: this.context?.currentUser?.city?.data?.attributes?.name, 
        zipCode: businessDetailAttributes.zipcode ,
        agreeTermsAndCondition: false 
      };
  
      this.setState({
        personalDetailsValues,
        entityDetailsValues,
        businessDetailsValues
      });
    }
   }

   
  async componentDidMount() {
    super.componentDidMount();
    this.getCountryList();
    const account_type = await getStorageData('account_type')
    this.setState({
      isSubscription:true,
      account_type:account_type},()=>{
        let accType = account_type === 'agency' ? 'Agency' : 'Buddy'
        this.getSubscriptionList(accType)
      })
      this.getpickPlanCall(account_type.charAt(0).toUpperCase() + account_type.slice(1))
      
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {

    if(this.context?.currentUser?.subscription_billing?.auto_renewal !== undefined && this.state.ischecked !== this.context?.currentUser?.subscription_billing?.auto_renewal  && !this.state.initialLoad)
    {
      this.setState({ischecked : this.context?.currentUser?.subscription_billing?.auto_renewal , initialLoad: true})
      this.setState({isSubscription:this.context?.currentUser?.subscription_billing,isSubscriptionPlan:this.context?.currentUser?.subscription_plan},)
      
    }

    if(this.context?.currentUser?.subscription_billing?.auto_renewal !== undefined && !this.context?.currentUser?.subscription_billing?.auto_renewal && !this.state.initialLoad)
    {
      this.setState({initialLoad: true})
      this.setState({isSubscription:this.context?.currentUser?.subscription_billing,isSubscriptionPlan:this.context?.currentUser?.subscription_plan})


    }
    
    this.setProfileData();
    
    }
    handleValueChange = (field: string, value: string) => {
      if(field === 'mobile'){
        this.setState({
          phoneErrorMsg: '',
          isPhoneVarified: false
        })
      }else if(field === 'email'){
        this.setState({
          emailErrorMsg: '',
          isEmailVarified: false
        })
      }
      this.setState((prev) => ({
        ...prev,
        [field]: value
      }))
    }
    
  

  handleVerifyApiEmail = (email: string): boolean => {
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };

    const attr = {
      email: email
    };

    const dataList = {
      attributes: attr
    };

    const httpBody = {
      data: dataList
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendVerifyEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendVerifyEmailApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCountryId = (country: string | null) => {
    return country
      ? (this.state.countryList.find(
          (countryData: CountryData) => countryData?.name === country
        )?.id || null)
      : null;
  };
  
  getCityId = (city: string | null) => {
    return city
      ? (this.state.cityList.find(
          (cityData: CityData) => cityData?.name === city
        )?.id || null)
      : null;
  };
  
  
    // API Response Functions
  
  
  
    getCityListApiResponse = (responseJson: any, errorReponse: any) => {
      const citiesDataArray = responseJson.cities_data.data;
      if (citiesDataArray) {
        const cityList = citiesDataArray.map(
          (city: any) => {
            const attributes = city.attributes;
            attributes.id = city.id;
            return attributes;
          }
        );
    
        this.setState({
          cityList: cityList,
        });
      }
    }

    handleTravelAgencyEditResponse = (responseJson: any, errorResponse: any) => {
      if (responseJson && responseJson.messages && responseJson.messages.length > 0) {
          const message = responseJson.messages[0];
          if (message.account) {
              this.setState({
                  responseMsg: message.account,
                  responseModal: true,
                  isSuccessIcon: true
              });
             window.location.reload()
          } else {
              this.setState({
                  responseMsg: "Error: Unexpected response from the server",
                  responseModal: true,
                  isSuccessIcon: false
              });
          }
      } else {
          this.setState({
              responseMsg: "Error: Unexpected response from the server",
              responseModal: true,
              isSuccessIcon: false
          });
      }
  };

   async convertUrlToFile(url:any, filename:any, mimeType:any) {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  }
    
    traveleAgencyEditRequest = async(values:any) => {   
    const formdata = new FormData();
    if(this.state.tab =='0'){
      formdata.append("first_name", values?.fname);
      formdata.append("last_name", values?.lname);
      formdata.append("email",values?.email);
      formdata.append("password", values?.password);
      formdata.append("service_provider", values?.service_provider_type)
      formdata.append("facebook_link", values?.facebook_link);
      formdata.append("instagram_link", values?.instagram_link);
      formdata.append("website_link", values?.website_link);
      formdata.append("full_phone_number",values?.country_code + values?.phoneNumber.replace(/\s/g, ''));
    }
      if( this.state?.tab ==='Entity Details' &&  this.state.account_type == 'agency' ){
        if (typeof values?.agency_logo === 'string' && values.agency_logo.startsWith('http')) {
          const agencyLogoFile = await this.convertUrlToFile(
            values.agency_logo,
            "agency_logo.jpg", 
            "image/jpeg"
          );
          formdata.append("entity_details[agency_logo]", agencyLogoFile);
        } else {
          formdata.append("entity_details[agency_logo]", values.agency_logo);
        }
        
        if (values?.commercialPdf) {
          const commercialPdf = await this.convertUrlToFile(
            values.commercialPdf,
            "commercialPdf.pdf", 
            "application/pdf" 
          );
          formdata.append("entity_details[commerical_registration_document]", commercialPdf);
        }
      formdata.append("entity_details[id_number]", values?.id_number);
      formdata.append("entity_details[display_name]", values?.agency_display_name);
      formdata.append("entity_details[company_legal_name]", values?.company_legal_name);
      formdata.append("entity_details[about_me]", values?.about_me)
      } else if(this.state?.tab ==='Entity Details' && this.state.account_type== 'buddy'){
        formdata.append("entity_details[id_number]", values?.buddy_id_number);
        formdata.append("entity_details[display_name]", values?.buddy_name);
         values?.buddy_about_me && formdata.append("entity_details[about_me]", values?.buddy_about_me);
         values?.buddy_profile_picture?.name && formdata.append("profile_image", values?.buddy_profile_picture);
         values?.buddy_commercialPdf?.name && formdata.append("entity_details[commerical_registration_document]", values?.buddy_commercialPdf);
         values?. tourismPdf?.name && formdata.append("entity_details[tourism_certificate]", values?.tourismPdf);
         values?.buddy_permitPdf?.name && formdata.append("entity_details[freelance_permit]", values?.buddy_permitPdf);
         values?.vat_number && formdata.append("entity_details[vat_number]", values?.buddy_vat_number);
      }
      if(this.state?.tab==='Business Details'){
      formdata.append("business_details[bank_name]", values?.bankName);
      formdata.append("business_details[account_number]", values?.accountNumber);
      formdata.append("business_details[iban]", values?.iban);
      formdata.append("business_details[street_name]", values?.streetName);
      formdata.append("business_details[account_name]", values?.accountName);
      formdata.append("business_details[city_id]", this.getCityId(values?.city) as string);
      formdata.append("business_details[zipcode]", values?.zipCode);
      }
       const authToken =  await getAuthToken()
      const header = {
        Authorization: `Bearer ${authToken}`
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.apiCallIdTravelAgencyEdit = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.travelersRegistrationEndpoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );

     
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
  };

  handleViewFile = (fileUrl: string, isLink: boolean) => {
    if(isLink){
      if (fileUrl !== null) {
        window.open(fileUrl, '_blank');
    }
    }
};

handleCloseResponseModal = () => {
  this.setState({
    responseModal: false
  }, () => handleScrollToTop())
}
  perfectPlanModalOpen = () => {
    this.setState({ perfectPlanModal: true })
  };

  perfectPlanModalClose = () => {
    this.setState({ perfectPlanModal: false,
       showOnclickMsg:false,
      subscriptionMsg : ""
     })
  };

  getStartedButton = () => {
    this.setState({responseModal: true, isSuccessIcon: true, responseMsg: "Auto renuewal option is turned on successfully", perfectPlanModal: false})
  };

  editSecurityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editSecurityCard = event.target.value;
    this.setState({
      securityCode: editSecurityCard
    });
  };

  editExpirationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editExpiration = event.target.value;
    this.setState({
      expiration: this.editFormatExpiration(editExpiration)
    });
  };
  
  editFormatExpiration = (inputExpiration: string) => {
    const numericValue = inputExpiration.replace(/\D/g, '');
    const formattedValue = numericValue.slice(0, 4);
    const month = formattedValue.slice(0, 2);
    const year = formattedValue.slice(2, 4);
    return formattedValue.length >= 3 ? `${month}/${year}` : formattedValue;
  };

  changeCardHolderName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editCardHolderName = event.target.value;
    this.setState({
      cardHolderName: editCardHolderName
    });
  };

  changeCardNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const editCardNumber = event?.target.value;
   const numericValue = editCardNumber?.replace(/\D/g, '');
   const formattedCardNumber = numericValue?.replace(/(\d{4})/g, '$1 ').trim();
    this.setState({
      cardNumber: formattedCardNumber
    });
  };

renewManually = () => {
this.setState({linkCardOpen: true})
};

renewManuallyCloseModal = () => {
  this.setState({linkCardOpen: false})
};

handleChangePlan = () => {
  this.setState({linkCardOpen: false, perfectPlanModal: true})
};

profileApiCall = async (valueData: {
  contentType?: string;
  method?: string;
  endPoint?: string;
  body?: {};
  apiType?: string;
  type?: string;
}) => {
  const token = await getAuthToken()
  let { contentType, method, endPoint, body, apiType } = valueData;
  let header = {
      "Content-Type": contentType,
      "Authorization": `Bearer ${token}`
  };
  let profileRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );
  profileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
  );
  profileRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
  );
  body &&
  profileRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          apiType === "" ? JSON.stringify(body) : body
      );
      profileRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  runEngine.sendMessage(profileRequestMessage.id, profileRequestMessage);
  return profileRequestMessage.messageId;
};

getpickPlanCall = async (accountType: string) => {
  this.getpickPlanApiCallId = await this.profileApiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.subScriptionPlan}?subscription_user_type=${accountType}`,
  })
};

getpickPlanApiSucessCallBack = (response: PlanResponse) => {
  if(response){
    const filterResponse = response.data.filter((filterData : Plan) => {
      return filterData.attributes.plan_type == "paid"
    })
    this.setState({pickPlanData: filterResponse })
  }
};

subscriptionPlanHandle = (monthly_amount: number,yearly_amount: number) => {
  if (monthly_amount > 0 && yearly_amount === 0.0) {
    return  false;  
  } else if (yearly_amount > 0 && monthly_amount === 0.0) {
    return true ;   
  } 
};

subscriptionPlanAmount = (monthly_amount: number,yearly_amount: number) => {
  if (monthly_amount > 0 && yearly_amount === 0.0) {
    return monthly_amount;  
  } else if (yearly_amount > 0 && monthly_amount === 0.0) {
    return yearly_amount;   
  } 
};

 getPlanType = () => {
  const plan = this.state.isSubscriptionPlan
  if (plan?.monthly_amount === 0.0 && plan?.yearly_amount === 0.0) {
     return i18next.t("travelAgency.mySubscription.trialPlan");
  } else if (plan?.monthly_amount !== 0.0 && plan?.yearly_amount === 0.0) {
     return i18next.t("travelAgency.mySubscription.monthlyPlan");
  } else if (plan?.yearly_amount !== 0.0 && plan?.monthly_amount === 0.0) {
     return i18next.t("travelAgency.mySubscription.annualPlan");
  } 
};

getPlanTypeClass = () => {
  const plan = this.state.isSubscriptionPlan
  const { classes } = this.props
  if (plan?.monthly_amount === 0.0 && plan?.yearly_amount === 0.0) {
     return classes.trailPlanButton;
  } else if (plan?.monthly_amount !== 0.0 && plan?.yearly_amount === 0.0) {
     return classes.monthlyPlanButton;
  } else if (plan?.yearly_amount !== 0.0 && plan?.monthly_amount === 0.0) {
     return classes.annualPlanButton;
  } 
};

checkCardInformation = () => {
  this.setState({checkCardInformation: true})
};

getSubscriptionList = async (userType: string) => {
  if(userType !== "")
  {
  const requestMessageData = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.apiCallIdSubscriptionPlan = requestMessageData.messageId;
  requestMessageData.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.subScriptionPlan}?subscription_user_type=${userType}`
  );
  requestMessageData.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessageData.id, requestMessageData);
  }
}

subscriptionPlanListResponse = (responseJson: any, errorReponse: any) => {
  const subScriptionList= responseJson.data;
    if (subScriptionList) {
      this.setState({
        subScriptionPlan: subScriptionList,
      });
    }
}

showMessage = (msg:string) => {
  this.setState({showOnclickMsg:true,
    subscriptionMsg:msg
  })
}
  // Customizable Area End
}
