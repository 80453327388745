import React, { ChangeEvent } from "react";

// Customizable Area Start
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import DatePicker from 'react-datepicker';
import {
  Modal, MenuItem, TextField, Grid, Container, RadioGroup,
  FormLabel, Radio, Typography, Box, InputAdornment, IconButton,
  FormControl, FormControlLabel, Checkbox, Link, Button, Select
} from "@material-ui/core";
import { parsePhoneNumber } from "libphonenumber-js";
import { Formik, ErrorMessage, FormikErrors, FormikTouched } from 'formik';
import {
  Visibility, VisibilityOff, Check, KeyboardArrowDown
} from "@material-ui/icons";
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { primaryColor } from "../../../components/src/theme";
import { congratsTick, close, cameraIcon, calendarImg } from "./assets";
import {
  borderColor, checkIfFilesAreCorrectType, checkIfFilesAreTooBig,
  helperTextValue, isErrorInField, formatDate
} from "../../../components/src/utils";
import 'react-datepicker/dist/react-datepicker.css';
import "./EmailAccountRegistration.css";
import i18next from 'i18next';


export const signUptheme = createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        fontFamily: "Inter",
        fontStyle: 'normal',
        lineHeight: '150%',
        fontWeight: 400,
        fontSize: '16px',
        letterSpacing: 0,
        color: 'rgba(33, 36, 38, 0.65)',
        '@media (max-width: 360px)': {
          fontSize: '14px',
        }
      }
    },
    MuiFormControl: {
      root: {
        minWidth: 'auto'
      }
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#FFF",
        padding: "16px 20px 14px",
        border: `1px solid`,
        borderRadius: '8px',
        fontFamily: "Inter",
      },
      input: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: 0,
        lineHeight: '150%',
        padding: 0,
        color: 'rgba(33, 36, 38, 0.90)',
        height: 'auto'
      }
    },
    MuiFormHelperText: {
      root: {
        color: '#F00',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        marginTop: '4px',
        letterSpacing: 0,
        '&.Mui-error': {
          color: '#F00',
        }
      },
    },
    MuiSelect: {
      select: {
        '&.MuiSelect-select': {
          columns: 2,
          paddingRight: 0,
          whiteSpace: 'normal',
          gap: '12px',
          display: 'flex'
        }
      },
      iconOutlined: {
        right: '20px'
      },
      icon: {
        right: '10px'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '16px',
        fontFamily: 'Inter',
        lineHeight: '150%',
        fontWeight: 500,
        '@media (max-width: 360px)': {
          fontSize: '14px',
          minHeight: '38px'
        }
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: 0
      },
      root: {
        borderRadius: '8px'
      },
      notchedOutline: {
        border: 'none'
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        gap: '12px'
      }
    }
  }
});

const styles = {
  formHelperText: {
    color: '#F00',
    fontSize: '12px',
    fontWeight: 400,
    marginTop: '4px'
  },
  errorBlock: {
    borderRadius: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '12px',
    flexShrink: 0,
    backgroundColor: '#FFF2F2',
    borderLeft: '8px solid #FF0000',
    padding: '12px 16px 12px 12px',
    marginBottom: '24px',
  },
  buttonSelectStyle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    color: 'rgba(33, 36, 38, 0.90)',
    fontSize: '16px'
  },
};


export function SelectInput({
  label,
  name,
  value,
  onChange,
  error,
  touched,
  options,
  placeholder,
}: {
  label: string,
  name: string,
  value: null | string,
  onChange: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>,
  error: string | undefined | string[] | FormikErrors<any> | FormikErrors<any>[],
  touched: FormikTouched<any>,
  options: { label: string, value: string }[],
  placeholder: string,
}) {
  return (
    <FormControl variant="outlined" fullWidth>
      <Typography
        style={{
          marginBottom: "10px",
          fontWeight: 500
        }}
      >
        {label}<Typography component={'span'} style={{ color: '#F00' }}> *</Typography>
      </Typography>
      <Select
        displayEmpty
        style={{
          borderColor: error && touched[name] ? 'red' : '#C3CAD9'
        }}
        className="inputField"
        inputProps={{
          className: value === null ? 'inputGray' : 'inputBlack',
        }}
        name={name}
        value={value ? value : ""}
        onChange={(e) => {
          onChange(name, e.target.value);
          touched[name] = true;
        }}
        IconComponent={KeyboardArrowDown}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          PaperProps: {
            style: {
              maxHeight: "250px",
            },
          },
        }}
      >
        <MenuItem disabled value={""}>{placeholder}</MenuItem>
        {options.map((item, index) => (
          <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
      <ErrorMessage name={name} component='span'>
        {(message: string) => <span style={styles.formHelperText}>{message}</span>}
      </ErrorMessage>
    </FormControl>
  );
}

export const isValidPhoneNumber = (mobile_number: string, id: string): boolean => {
  try {
    const parsedPhoneNumber = parsePhoneNumber(id + mobile_number);
    return !!parsedPhoneNumber && parsedPhoneNumber.isPossible();
  } catch (error) {
    return false
  }
};

export const validationSchema = {
  profile_picture: Yup.mixed()
    .test('is-correct-file',i18next.t('createAccount.upload_file_hint'), checkIfFilesAreCorrectType)
    .test('is-big-file', i18next.t('createAccount.file_size_hint'), checkIfFilesAreTooBig),
  first_name: Yup.string().required(() => i18next.t('createAccount.enter_first_name_error')),
  last_name: Yup.string().required(() => i18next.t('createAccount.enter_last_name_error')),
  email: Yup.string().email(i18next.t('createAccount.enter_valid_email_error'))
    .required(i18next.t('createAccount.enter_valid_email_error')),
  gender: Yup.string().required(() => i18next.t('createAccount.pick_gender_error')),
  country_id: Yup.string().required(() => i18next.t('createAccount.select_country_error')).nullable(),
  nationality_id: Yup.string().required(() => i18next.t('createAccount.select_nationality_error')).nullable(),
  mobile_number: Yup.string().test(
    'phoneno', () => i18next.t('createAccount.enter_valid_mobile_error'), function (value) {
      return value && isValidPhoneNumber(value, this.parent.selectedCountry);
    }).required(() => i18next.t('createAccount.enter_mobile_errorForgot password')),
}

export const isDefaultImage = (profile_picture: any, error: any) => {
  return profile_picture
    && typeof profile_picture === 'object'
    && !error
}

export const profilePicture = (isDefaultImg: boolean, value: any, image?: string) => {
  if (isDefaultImg && value) {
    return URL.createObjectURL(value);
  } else {
    return image ? image : cameraIcon;
  }
}

export const isErrorInEmail = (isEmailVerified: boolean, touched: boolean | FormikTouched<any> | FormikTouched<any>[] | undefined, error: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined) => {
  return Boolean(!isEmailVerified && touched && error)
}

export const verifyEmailBtn = (isEmailVerified: boolean,
  email: string, errors: FormikErrors<any>,
  setFieldError: (field: string, message: string | undefined) => void,
  handleVerfiyEmail: (email: string, errors: FormikErrors<any>,
    setFieldError: (field: string, message: string | undefined) => void) => void) => {
  return isEmailVerified ? (
    <InputAdornment position='end'>
      <div style={{ display: 'flex', gap: '5px' }}>
        <Check style={{ color: '#6F964C', fontSize: '20px', fontWeight: 600 }} />
        <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 600, padding: 0 }}>
        {i18next.t('createAccount.verified')}</Typography>
      </div>
    </InputAdornment>
  ) : (
    <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={() => handleVerfiyEmail(email, errors, setFieldError)}
        style={{ fontSize: "14px", fontFamily: "Inter", fontWeight: 500, color: primaryColor, padding: 0 }}
        disabled={!email}
      >
        {i18next.t('createAccount.verify')}
      </IconButton>
    </InputAdornment>
  )
}

export const verifyEmailModal = ({ formattedTime, instance }: any) => {
  return <Modal
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
    open={instance.state.isOpen}
    onClose={instance.openCloseModal}
    aria-labelledby="simple-modal-title"
    aria-describedby="simple-modal-description"
  >
    <div className="verify-email-modal">
      <span className="close-btn" onClick={instance.openCloseModal}><img src={close} alt="close" /></span>
      <h4>{i18next.t('createAccount.verify_email')}</h4>
      <p>{instance.state.otpHeaderTitle}</p>
      <form onSubmit={instance.verifyOtp}>
        <div className="error-block-otp">
          <p><span className="required-field">{instance.state.otpErrorMessage}</span></p>
          <div className={`form-field otp-block`}>
            <div className="control">
              <OtpInput
                value={instance.state.otp}
                onChange={instance.handleOtpChange}
                numInputs={4}
                inputType="number"
                renderSeparator={<span> </span>}
                containerStyle={{ display: 'flex', justifyContent: 'center' }}
                inputStyle={{
                  width: "44px",
                  textAlign: "center",
                  margin: "0px 12px",
                  display: 'flex',
                  padding: '15.5px 0px 16.2px 0px',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  borderRadius: '9.778px',
                  ...instance.state.otpErrorMessage
                    ? { border: "1px solid #FF0000" }
                    : { border: "1px solid #D1D1D1" },
                  color: 'rgba(33, 36, 38, 0.90)',
                  fontFamily: 'Inter',
                  fontSize: '18px',
                  fontWeight: 500,
                }}
                renderInput={(props) => <input data-testid="otpinput" {...props} />}
              />
            </div>
            <span className="opt-timer-block">
              <div className="otp-time">
                {formattedTime}
              </div>
              <span role="button" className={instance.state.countdown === 0 ? 'resend-btn-enable' : 'resend-btn-disable'}
                onClick={() => instance.ResendOtpRequest()} >{i18next.t('createAccount.resend_code')}</span>
            </span>
          </div>
        </div>
        <button data-test-id='verify-otp-button' className="btn button" type="submit">{i18next.t('createAccount.verify')}</button>
      </form>
    </div>
  </Modal>
}

// Customizable Area End

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";

export default class EmailAccountRegistration extends EmailAccountRegistrationController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  errorBlock = (validateErrorMsg: string | null | {}) => {
    return validateErrorMsg
      ? <Box style={styles.errorBlock}>
        <Typography style={{ fontSize: '14px', color: '#F00' }}>{validateErrorMsg}</Typography>
      </Box>
      : null
  }

  passwordShowHideBtn = (enablePasswordField: boolean) => {
    return <InputAdornment position='end'>
      <IconButton
        aria-label='toggle password visibility'
        onClick={this.handleShowHidePassword}
        style={{ fontSize: "15px", textDecoration: 'none', padding: 0 }}
      >
        {enablePasswordField
          ? <Visibility style={{ height: '24px', width: '24px' }} />
          : <VisibilityOff style={{ height: '24px', width: '24px' }} />
        }
      </IconButton>
    </InputAdornment>
  }

  isImageUploadedText = (profileImage: any) => {
    return !!profileImage ? `Update` : `Add`
  }

  // Customizable Area End

  render() {

    // Customizable Area Start

    const { isEmailVerified, enablePasswordField, countryOption } = this.state;

    const formattedTime = `${Math.floor(this.state.countdown / 60)}:${(this.state.countdown % 60).toString().padStart(2, '0')}`;

    const countryCode = this.state.countriesData.length
      ? this.state.countriesData[0].country_code
      : ''

    const BirthDateInput = React.forwardRef(({ value, onClick }: any, ref: any): React.ReactElement => {
      return <div className={!value ? "date-placeholder" : 'date-value'} data-testid="singledate" onClick={onClick} ref={ref}>
        {!value ? i18next.t('createAccount.select_dob') : <span>{formatDate(value)}</span>}
        <img src={calendarImg} alt="" />
      </div>
    });

    const CustomFormLabel = withStyles({
      root: {
        color: 'rgb(33 36 38 / 65%)',
        fontFamily: 'Inter',
        '&$focused': {
          color: 'rgb(33 36 38 / 65%)',
        },
      },
      focused: {}
    })(FormLabel);
    // Customizable Area End

    return (
      <div className="main-wrapper">
        {/* Customizable Area Start */}
        <ThemeProvider theme={signUptheme}>
          <Container maxWidth='lg'>
            <div className="guest-form customer-signup-container">
              <Typography component={"h2"} className="title mb-root48">
              {i18next.t('createAccount.create_account')}
              </Typography>

              <Formik
                validateOnChange={true}
                initialValues={{
                  first_name: '',
                  last_name: '',
                  email: '',
                  mobile_number: '',
                  birth_day: null,
                  password: '',
                  gender: '',
                  checked: false,
                  country_id: null,
                  nationality_id: null,
                  profile_picture: null,
                  selectedCountry: countryCode
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  ...validationSchema,
                  password: Yup.string().min(5, ()=>{i18next.t('createAccount.valid_password_error')}).required(()=>{i18next.t('createAccount.valid_password_error')}),
                })}
                onSubmit={(values, { setFieldError }) => { this.handleCreateAccount(values, setFieldError) }}
              >
                {({ handleSubmit, values, setFieldValue, errors, touched, setFieldError }) => {
                 
                 const isDefaultImg = isDefaultImage(values.profile_picture, errors.profile_picture)

                  const profile_picture = profilePicture(isDefaultImg, values.profile_picture)
                  return <form onSubmit={handleSubmit}>

                    <Box className="flexGap8">
                      <Typography style={{ fontWeight: 500 }}>
                      {i18next.t('createAccount.profile_picture')}
                      </Typography>
                      <Typography >
                        ({i18next.t('createAccount.optional')})
                      </Typography>
                    </Box>

                    <Box style={{ marginTop: '16px' }}>
                      <Box className="flexGap24">
                        <Box>
                          <Typography className='profile-image' style={{
                            display: "flex", alignItems: 'center', borderRadius: '50%',
                            background: 'rgb(247, 247, 247)', justifyContent: 'center',
                            border: '1px solid #C3CAD9', padding: '0', overflow: 'hidden', cursor: 'pointer'
                          }} component={"label"} htmlFor="profilePic">
                            <img src={profile_picture} alt="Profile Image" className={`${isDefaultImg ? 'default' : 'defaultIcon'}`}
                              style={{ objectFit: 'cover' }} />
                            <input type="file" id="profilePic" name="profile_picture" hidden
                              placeholder="Upload your resume (.DOC, .DOCX, .PDF. Max file size 10MB)"
                              onChange={(e) => {
                                this.handleFileChange(e, setFieldValue)
                              }} />
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                          <Typography className='mt-24' style={{
                            color: primaryColor,
                            fontWeight: 600,
                            cursor: "pointer",
                          }} component="label" htmlFor="profilePic">
                            {this.isImageUploadedText(isDefaultImg)} {i18next.t('createAccount.profile_picture')}
                          </Typography>
                          <Typography style={{ fontSize: '14px', marginTop: "3px" }}>( {i18next.t('createAccount.format')} : JPG, JPEG and PNG)</Typography>
                          <Typography style={{ fontSize: '14px' }}>({i18next.t('createAccount.max_size')} : 5 MB)</Typography>
                          <Typography style={{
                            color: '#F00',
                            fontSize: '12px',
                            lineHeight: 'normal',
                            marginTop: '8px'
                          }}>{errors.profile_picture}</Typography>
                        </Box>
                      </Box>
                    </Box>

                    <span style={{ height: '1px', background: 'rgba(195, 202, 217, 0.20)', display: 'inline-block', width: '100%' }}
                      className="mtb-root32"></span>

                    {this.errorBlock(this.state.validateErrorMsg)}

                    <Grid container spacing={4} justifyContent="space-between" style={{ marginTop: '8px' }}>
                      <Grid item xs={12} md={6}>
                        <Typography
                          style={{ fontWeight: 500, marginBottom: '10px' }}
                        >
                           {i18next.t('createAccount.first_name')}<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={i18next.t('createAccount.enter_first_name')}
                          variant="standard"
                          name="first_name"
                          id="first_name"
                          onChange={(e) => {
                            setFieldValue('first_name', e.target.value)
                            touched.first_name = true
                          }}
                          error={isErrorInField(errors, touched, 'first_name')}
                          helperText={helperTextValue(errors, touched, 'first_name')}
                          value={values.first_name}
                          InputProps={{
                            className: 'inputField',
                            style: {
                              borderColor: borderColor(errors, touched, 'first_name'),
                            },
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography
                          style={{ fontWeight: 500, marginBottom: '10px' }}
                        >
                           {i18next.t('createAccount.last_name')}<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={i18next.t('createAccount.enter_last_name')}
                          variant="standard"
                          name="last_name"
                          id="last_name"
                          onChange={(e) => {
                            touched.last_name = true
                            setFieldValue('last_name', e.target.value)
                          }}
                          error={isErrorInField(errors, touched, 'last_name')}
                          helperText={helperTextValue(errors, touched, 'last_name')}
                          value={values.last_name}
                          InputProps={{
                            className: 'inputField',
                            style: {
                              borderColor: borderColor(errors, touched, 'last_name'),
                            },
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={4} justifyContent="space-between" style={{ marginTop: '8px' }}>
                      <Grid item md={6} xs={12}>
                        <Typography
                          style={{
                            marginBottom: "10px",
                            fontWeight: 500
                          }}
                        >
                          {i18next.t('createAccount.email_address')}<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={i18next.t('createAccount.enter_email')}
                          variant="standard"
                          name="email"
                          id="email"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue('email', e.target.value)
                            this.handleChangeEmail(e)
                            touched.email = true
                          }}
                          error={isErrorInEmail(isEmailVerified, touched.email, errors.email)}
                          helperText={helperTextValue(errors, { email: touched.email && !isEmailVerified }, 'email')}
                          defaultValue={values.email}
                          InputProps={{
                            endAdornment: verifyEmailBtn(isEmailVerified, values.email, errors, setFieldError, this.handleVerfiyEmail),
                            className: 'inputField',
                            style: {
                              borderColor: (isErrorInEmail(isEmailVerified, touched.email, errors.email))
                                ? 'red' : '#C3CAD9'
                            },
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Typography
                          style={{
                            marginBottom: "10px",
                            fontWeight: 500
                          }}
                        >
                          {i18next.t('createAccount.mobile_number')}<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={i18next.t('createAccount.enter_mobile')}
                          variant="standard"
                          name="mobile_number"
                          id="mobileNumber"
                          onChange={(e) => {
                            setFieldValue('mobile_number', e.target.value)
                            touched.mobile_number = true
                          }}
                          error={isErrorInField(errors, touched, 'mobile_number')}
                          helperText={helperTextValue(errors, touched, 'mobile_number')}
                          value={values.mobile_number}
                          inputProps={{
                            style: { paddingLeft: '16px' }
                          }}
                          InputProps={{
                            startAdornment: (
                              <TextField
                                select
                                name="selectedCountry"
                                variant="standard"
                                InputProps={{
                                  disableUnderline: true,
                                  style: {
                                    borderRight: `1px solid #C3CAD9`,
                                    padding: 0,
                                    fontWeight: 500,
                                    borderRadius: 0,
                                    borderBottom: 'none',
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    width: '125.76px',
                                  },
                                }}
                                SelectProps={{
                                  classes: {
                                    select: 'country-flag'
                                  },
                                  MenuProps: {
                                    anchorOrigin: {
                                      vertical: "bottom",
                                      horizontal: "left",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                    PaperProps: {
                                      style: {
                                        maxHeight: "250px",
                                        maxWidth: "130px"
                                      },
                                    },
                                    MenuListProps: {
                                      className: "countryCode-list-item",
                                    },
                                  },
                                }}
                                value={values.selectedCountry}
                                onChange={(e) => setFieldValue('selectedCountry', e.target.value)}
                              >
                                {this.state.countriesData.map((option) => (
                                  <MenuItem
                                    key={option.country_code + option.name}
                                    value={option.country_code}
                                    className="dropdown-menu-list"
                                    style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    {option.country_code}&nbsp;{<img
                                      style={{ borderRadius: '4px' }} className="flag-image"
                                      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${option.id}.svg`}
                                    />}
                                  </MenuItem>
                                ))}
                              </TextField>
                            ),
                            className: 'inputField',
                            style: {
                              borderColor: borderColor(errors, touched, 'mobile_number')
                            },
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={4} justifyContent="space-between" style={{ marginTop: '8px' }}>
                      <Grid item md={6} xs={12}>
                        <Box style={{ display: 'flex', gap: '8px', width: '100%', marginBottom: "10px", flexWrap: 'wrap' }}>
                          <Typography style={{ fontWeight: 500 }}>
                          {i18next.t('createAccount.birthday')}
                          </Typography>
                          <Typography
                            style={{
                              fontWeight: 400
                            }}
                          >
                            ({i18next.t('createAccount.optional')})
                          </Typography>
                        </Box>
                        <DatePicker
                          selected={values.birth_day}
                          name="birth_day"
                          onChange={(date) => setFieldValue('birth_day', date)}
                          placeholderText={i18next.t('createAccount.select_dob')}
                          dateFormat={'dd MMM yyyy'}
                          customInput={<BirthDateInput />}
                          maxDate={new Date()}
                          showYearDropdown
                          showMonthDropdown
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <SelectInput
                          label={i18next.t('createAccount.nationality')}
                          name="nationality_id"
                          value={values.nationality_id}
                          onChange={setFieldValue}
                          error={errors.nationality_id}
                          touched={touched}
                          options={countryOption}
                          placeholder={i18next.t('createAccount.select_nationality')}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={4} justifyContent="space-between" style={{ marginTop: '8px' }}>
                      <Grid item md={6} xs={12}>
                        <SelectInput
                          label={i18next.t('createAccount.country_of_residence')}
                          name="country_id"
                          value={values.country_id}
                          onChange={setFieldValue}
                          error={errors.country_id}
                          touched={touched}
                          options={countryOption}
                          placeholder={i18next.t('createAccount.select_country')}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: "10px", flexWrap: 'wrap' }}>
                          <Typography style={{ fontWeight: 500 }}>
                          {i18next.t('createAccount.password')}<span style={{ color: 'red' }}> *</span>
                          </Typography>
                          <Typography style={{ fontSize: "14px" }}>
                            ({i18next.t('createAccount.password_hint')})
                          </Typography>
                        </Box>
                        <TextField
                          fullWidth
                          placeholder={i18next.t('createAccount.enter_password')}
                          variant="standard"
                          name="password"
                          autoComplete="off"
                          className="email_input"
                          type={enablePasswordField ? 'text' : 'password'}
                          onChange={(e) => {
                            setFieldValue('password', e.target.value)
                            touched.password = true;
                          }}
                          error={isErrorInField(errors, touched, "password")}
                          helperText={helperTextValue(errors, touched, 'password')}
                          value={values.password}
                          InputProps={{
                            className: 'inputField',
                            endAdornment: (this.passwordShowHideBtn(enablePasswordField)),
                            style: {
                              borderColor: borderColor(errors, touched, 'password'),
                            },
                            disableUnderline: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Box className="gender-radio-list">
                      <FormControl component="fieldset">
                        <RadioGroup aria-label="gender" name="gender"
                          value={values.gender}
                          className="radio-list"
                          onChange={(e) => {
                            setFieldValue('gender', e.target.value)
                            touched.gender = true;
                          }}>
                          <CustomFormLabel style={{
                            fontWeight: 500,
                            alignItems: 'center', display: 'flex',
                          }}>
                            {i18next.t('createAccount.gender')}<span style={{ color: 'red' }}> *</span>
                          </CustomFormLabel>
                          <FormControlLabel value="Male" name="gender"
                            control={<Radio style={{ color: 'rgba(33, 36, 38, 0.90)', padding: 0 }} />}
                            label={<span style={styles.buttonSelectStyle}>
                              {i18next.t('createAccount.male')}
                            </span>} />
                          <FormControlLabel value="Female" name="gender"
                            control={<Radio style={{ color: 'rgba(33, 36, 38, 0.90)', padding: 0 }} />}
                            label={<span style={styles.buttonSelectStyle}>
                              {i18next.t('createAccount.female')}
                            </span>} />
                        </RadioGroup>
                      </FormControl>
                      <Typography component={'span'} style={{ ...styles.formHelperText }}>{helperTextValue(errors, touched, 'gender')}</Typography>
                    </Box>

                    <Box style={{ marginTop: '24px' }}>
                      <FormControlLabel
                        control={<Checkbox
                          style={{ color: primaryColor, fontFamily: "Inter", padding: 0, marginTop: '2px' }}
                          checked={values.checked}
                          onChange={(e) => {
                            setFieldValue("checked", e.target.checked)
                            this.setState({ validateErrorMsg: null })
                          }}
                          name="checked" />}
                        name="checked"
                        label={
                          <span style={{ fontSize: '14px', fontWeight: 500 }}>
                            {i18next.t('createAccount.consent')}{" "}
                            <Link target="_blank" href="/privacy-policy" style={{ color: primaryColor }}>{i18next.t('createAccount.privacy_policy')}</Link> and{" "}
                            <Link target="_blank" href="/terms-of-use" style={{ color: primaryColor }}>{i18next.t('createAccount.terms_of_use')}</Link>
                          </span>
                        }
                        style={{ gap: '10px', alignItems: 'start' }}
                      />
                    </Box>

                    <Box className="mt-root47">
                      <Button
                        style={{
                          backgroundColor: primaryColor,
                          color: '#ffffff',
                          textTransform: 'initial',
                          borderRadius: '44px',
                          fontSize: '16px',
                          fontFamily: "Inter",
                          fontWeight: 500,
                          maxWidth: '360px',
                          height: '52px',
                          minWidth: '160px',
                          width: '100%'
                        }}
                        type="submit"
                      >
                        {i18next.t('createAccount.sign_up')}
                       
                      </Button>
                    </Box>
                  </form>
                }}
              </Formik>

              <Box style={{ gap: '8px', display: 'flex', flexWrap: 'wrap' }} className="mt24">
                <Typography style={{ fontSize: '14px' }}> {i18next.t('createAccount.have_account')}
                </Typography>
                <Typography style={{ fontSize: '14px', color: primaryColor, cursor: 'pointer' }}
                  component={"span"} data-test-id="btnSignIn"
                  onClick={() => this.props.navigation.navigate("EmailAccountLoginBlock")}>
                   {i18next.t('createAccount.sign_in')}
                </Typography>
              </Box>

              {verifyEmailModal({ formattedTime: formattedTime, instance: this })}
              <Modal
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                open={this.state.isSuccessOpen}
                onClose={this.handleSuccessModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <div className="congratulations-modal">
                  <span className="close-btn" data-test-id="closeSuccessModal" onClick={() => this.handleSuccessModal()}><img src={close} alt="close" /></span>
                  <img src={congratsTick} alt="" />
                  <h4>{i18next.t('createAccount.congratulations')}!</h4>
                  <p>{i18next.t('createAccount.account_created')}</p>
                </div>
              </Modal>
            </div>
          </Container>
        </ThemeProvider >
        {/* Customizable Area End */}
      </div>
    );
  }
}
