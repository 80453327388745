import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { capitalizeFirstLetter, getAuthToken } from "../../../components/src/utils";
import ApiRequest from "../../../components/src/ApiRequest";
import { getStorageData } from "../../../framework/src/Utilities";
import { AuthContext } from "../../user-profile-basic/src/AuthContext.web";

interface UserData {
  name: string;
  email: string;
  mobile: string;
}

interface PaymentDetails {
  tripAmountPerPerson: number;
  discountedPricePerPerson: number;
  numberOfPeople: number;
  total: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  orders: any;
  orderCount: any;
  isCancelVisible: boolean;
  isRateVisible: boolean;
  cancelDialog: boolean;
  starCount: number;
  comment: string;
  token: string;
  itemDetail: any;
  activeOrderId: number;
  activeItemId: number;
  activeAddress: any;
  isExpanded: boolean;
  tab: string;
  isOpen: boolean;
  noOfPeople: any;
  seats: number;
  bookingDiscount: boolean;
  tripDetails: Record<string, any>;
  loading: boolean;
  activeFilter: string;
  anchorEle: null | Element;
  searchValue: null | string;
  currentTrip_country_id: string;
  currentTrip_type: string;
  currentTrip_id: string;
  prevTrip_id: string;
  exploreMoreTrips: Record<string, any>[];
  similarTrips: Record<string, any>[];
  selectedDate: {id: string, date: string };
  trip_schedules: Array<{id: string, date: string}>
  userData: UserData;
  paymentDetails: PaymentDetails,
  seatsDateError: string;
  paymentResponse: any;
  openShareModal: boolean;
  share_trip: string;
  openReviewModal: boolean;
  reviewRating: number;
  reviewTitle: string;
  reviewDesc: string;
  responseStatusModal: boolean;
  responseMsg: string;
  isSuccessResponse: boolean;
  bookingsLoader: boolean;
  tripAndBookingId: any;
  cartItems :Record<string, any>[];
  isDeleteModalOpen : boolean,
  existingTrip :any
  iscancelBtnClicked : boolean
  bookingDate : any
  bookednoOfPoeple : any,
  isEdit :boolean,
  isSuccess_updateBooking :boolean
  addToCartMsg: string;
  pdfUrl: string;
  paymentStatus:boolean;
  bookingPagination: {
    total_pages: string
    per_page: string
    current_page: string
    total_items: string
},
paymentFlow:boolean,
removed: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrdermanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersAPICallId: any;
  getOrdersCountAPICallId: any;
  getItemDetailAPICallId: any;
  cancelOrderAPICallId: any;
  rateOrderAPICallId: any;
  apiCallIdSimilarTrips: string = '';
  apiCallIdExloreTrips: string = '';
  apiCallIdDownloadInvoice: string = '';
  apiCallIdAvaibleSeats: string = '';
  apiCallIdBookingSummary: string = '';
  apiCallIdBookMyTrip: string = '';
  apiCallIdAddReview: string = '';
  addToCartapiCallId: string = '';
  debouncedSearch: ((callId: string, type: string, instance: any) => void) & { cancel: () => void };
  static contextType: any = AuthContext;
  cancelBookingApiCallID:string="";
  updateBookingApiCallId:string="";
  editLoader:boolean=false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {
      orders: [],
      orderCount: [],
      isCancelVisible: false,
      isRateVisible: false,
      cancelDialog: false,
      starCount: 0,
      token: "",
      itemDetail: "",
      activeOrderId: 0,
      activeItemId: 0,
      comment: "",
      activeAddress: [],
      isExpanded: false,
      tab: '0',
      isOpen: false,
      noOfPeople: 0,
      seats: 8,
      bookingDiscount: true,
      tripDetails: {},
      loading: false,
      activeFilter: "",
      anchorEle: null,
      searchValue: null,
      currentTrip_country_id: '',
      currentTrip_type: '',
      currentTrip_id: '',
      prevTrip_id: '',
      exploreMoreTrips: [],
      similarTrips: [],
      selectedDate: {id: '1', date: ''},
      trip_schedules: [],
      userData: {
        name: '',
        email: '',
        mobile: '',
      },
      paymentDetails: {
        tripAmountPerPerson: 0,
        discountedPricePerPerson: 0,
        numberOfPeople: 0,
        total: 0,
      },
      seatsDateError: "",
      paymentResponse: {},
      openShareModal: false,
      share_trip: '',
      openReviewModal: false,
      reviewTitle: '',
      reviewDesc: '',
      reviewRating: 0,
      responseStatusModal: false,
      responseMsg: "",
      isSuccessResponse: false,
      bookingsLoader: false,
      tripAndBookingId: '',
      cartItems :[],
      isDeleteModalOpen:false,
      existingTrip: {},
      iscancelBtnClicked :false,
      bookingDate :'',
      bookednoOfPoeple :'',
      isEdit :false,
      isSuccess_updateBooking :false,
      addToCartMsg: '',
      pdfUrl: '',
      paymentStatus:false,
      bookingPagination: {
        total_pages: "",
        per_page: "10",
        current_page: "1",
        total_items: "",
    },
    paymentFlow:false,
    removed: 0
    };
    this.debouncedSearch = this.debounce(this.getAvailableSeats, 300);
    this.goToAdvancedSearch=this.goToAdvancedSearch.bind(this)
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.handleSessionResponseMessage()
    this.getOrdersCountRequest()
    const booking_id = this.props.navigation?.match?.params?.bookingId ;
    this.editLoader = this.props.navigation?.match?.params?.editLoader ;
    if(!booking_id){
    this.getOrdersDataRequest()
    }
    this.getToken();
    const { pathname } = window.location;
    /* istanbul ignore next */
    if (pathname.startsWith('/TripDetails/')) {
      const ids = pathname.split('/').slice(2);
      const tripId = ids[0];
      this.getTripdetailsRequest(tripId as string)
      this.setState({tripAndBookingId: tripId})
    } else if (pathname.startsWith('/BookingSummary/')) {
      const {trip_id, noOfPeople, selectedDate  } = this.getParamValue();
      this.setState({
        currentTrip_id: trip_id,
        selectedDate: selectedDate?.selectedDate,
        noOfPeople,
        bookednoOfPoeple:selectedDate?.bookednoOfPoeple,
        isEdit : selectedDate?.isEdit,
        bookingDate : selectedDate?.bookingDate
              }, () => {
        this.getTripdetailsRequest(this.state.currentTrip_id);
        this.postOrderRequest("apiCallIdBookingSummary", "check_booking", this)
      })
    } else if (pathname.startsWith('/MyBookings')) {
      this.getOrdersDataRequest()
    }
    
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
     // Customizable Area End
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const responseOrderJson = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({
        paymentResponse: responseOrderJson.payload
      })
    }
    /* istanbul ignore next */
    const apiRequestCallIds = {
      [this.getOrdersCountAPICallId]: this.getCountsDataResponse,
      [this.getOrdersAPICallId]: this.getOrderDataResponse,
      [this.getItemDetailAPICallId]: this.getItemDetailsResponse,
      [this.rateOrderAPICallId]: this.rateOrderDataResponse,
      [this.showTripByIDApiCallId]: this.getTripDetailsResponse,
      [this.cancelOrderAPICallId]: this.cancelOrderApiResponse,
      [this.apiCallIdSimilarTrips]: this.similarTripListResponse,
      [this.apiCallIdExloreTrips]: this.exploreTripsResponse,
      [this.apiCallIdDownloadInvoice]: this.downloadInvoiceResponse,
      [this.apiCallIdAvaibleSeats]: this.availableSeatsResponse,
      [this.apiCallIdBookingSummary]: this.checkBookingResponse,
      [this.apiCallIdBookMyTrip]: this.bookMyTripResponse,
      [this.apiCallIdAddReview]: this.addReviewResponse,
      [this.addToCartapiCallId]: this.addToCartResponse,
      [this.cancelBookingApiCallID]: this.handleCancelBookingResponse,
      [this.updateBookingApiCallId]: this.handleUpdateBookingResponse

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }

  }
  // Customizable Area Start
 /* istanbul ignore next */

  checkIsExistingTrip = () => {
    const booking_id = this.props.navigation?.match?.params?.bookingId ;
    if (booking_id)
     {
      const booking = this.state.orders.find((order:any) => order.id === booking_id);
       this.setState({
        existingTrip : booking,
        selectedDate: {
          date : booking?.attributes?.trip_schedule?.scheduled_date,
          id :"1",
        },
        noOfPeople:booking?.attributes?.no_of_people,
        bookingDate:booking?.attributes?.trip_schedule?.scheduled_date,
        bookednoOfPoeple : booking?.attributes?.no_of_people,
        isEdit: true
        
       })
    }
  }
/* istanbul ignore next */
  componentDidUpdate(prevProps: Props) {
    const prevId = prevProps.navigation.getParam("id") || '';
    const currentId = this.props.navigation.getParam("id") || '';
  
    if (prevId !== currentId) {
      this.setState({
        prevTrip_id: prevId,
        currentTrip_id: currentId,
      }, () => {
        this.getTripdetailsRequest(this.state.currentTrip_id);
      });
    }
  }

  maxCharacters: number = 142;
  showTripByIDApiCallId: any;
/* istanbul ignore next */
handleSessionResponseMessage = async () => {
  const authToken = await getAuthToken(); 
  this.setState({ token: authToken, loading: true });

  const id = this.props.navigation.getParam('id');
  const { pathname } = this.props.navigation.history.location;

  if (pathname.includes('/BookingDetails')) {
    this.setState({ tripAndBookingId: id }, () => {
      this.getItemDetailDataRequest(id);
      this.downloadInvoiceRequestApi(authToken); 
    });
  } else if (['/OrderDetails', '/Ordermanagement', '/MyBookings'].includes(pathname)) {
    this.getOrdersDataRequest();
    this.getOrdersCountRequest();
  }
}
/* istanbul ignore next */
  cancelOrder = (orderId: number, itemId: number) => {
    this.setState({
      activeOrderId: orderId,
      activeItemId: itemId,
      isCancelVisible: !this.state.isCancelVisible,
    });
  };
  /* istanbul ignore next */
  rateOrder = () => {
    this.setState({ isRateVisible: !this.state.isRateVisible });
  };
  /* istanbul ignore next */
  hideCancelModal = () => {
    this.setState({ isCancelVisible: false });
  };
  /* istanbul ignore next */
  selectCancel = () => {
    this.setState({ isCancelVisible: false, cancelDialog: true });
  };

  // Request functions
  getOrdersCountRequest = async () => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      "Authorization": `Bearer ${this.state.token}`,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrdersCountAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.countsAPiEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getOrdersDataRequest = async () => {
  let endPoint =`${configJSON.ordersAPiEndPoint}?limit=10&page=${this.state.bookingPagination.current_page}`
  if(this.state.activeFilter !== null){
    endPoint =`${configJSON.ordersAPiEndPoint}?limit=10&page=${this.state.bookingPagination.current_page}&status=${this.state.activeFilter}`
  }
  
    const token = await getAuthToken()
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      "Authorization": `Bearer ${token}`,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getOrdersAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({bookingsLoader: true, orders: []})
  };
/* istanbul ignore next */
  getItemDetailDataRequest = async (id: string) => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      "Authorization": `Bearer ${authToken}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getItemDetailAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ordersAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
/* istanbul ignore next */
  cancelOrderDataRequest = () => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token: this.state.token,
    };
    var data = {
      item_id: this.state.activeItemId,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.cancelOrderAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ordersAPiEndPoint + `/${this.state.activeOrderId}/cancel_order`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
/* istanbul ignore next */
  rateOrderDataRequest = (id: number) => {
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      token: this.state.token,
    };
    var data = {
      catalogue_id: id,
      rating: this.state.starCount,
      comment: this.state.comment,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rateOrderAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.rateAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
/* istanbul ignore next */
  getTripdetailsRequest = async (id: string) => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.ordersApiContentType,
      "Authorization": `Bearer ${authToken}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showTripByIDApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.showTripByIDEndPoint}/${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  /* istanbul ignore next */
  getSimilarTripsRequest = async () => {
    const { currentTrip_type: type} = this.state;
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `${configJSON.tripsByTypeEndpoint}?type=${type}`,
      method: "GET",
    });
    this.apiCallIdSimilarTrips = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  /* istanbul ignore next */
  exploreMoreTripRequest = async () => {
    const { currentTrip_type: type} = this.state;
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.productApiContentType,
      Authorization: `Bearer ${authToken}`,
    };
    const endpoint = `${configJSON.tripsByTypeEndpoint}?type=${type}`;

    const requestMessage = ApiRequest({
      header,
      endPoint: endpoint,
      method: 'GET',
    });
    this.apiCallIdExloreTrips = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
/* istanbul ignore next */
  postOrderRequest = async (callId: string, type: string, instance: any) => {
    const { currentTrip_id, selectedDate, noOfPeople} = this.state;
    const formdata = new FormData();
     // Helper function to append a field and value to formdata if the value exists
     const appendFieldIfValueExists = (field: string, value: any) => {
      if (value) {
        formdata.append(field, value);
      }
    };
    // Append fields with values if they exist
    appendFieldIfValueExists('trip_id', currentTrip_id);
    appendFieldIfValueExists('trip_schedule_id', selectedDate.id);
    appendFieldIfValueExists('quantity', noOfPeople);
    appendFieldIfValueExists('type', type);
    
    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
    };
    const endpoint = `${configJSON.ordersAPiEndPoint}`;

    const requestMessage = ApiRequest({
      header,
      endPoint: endpoint,
      payload: formdata,
      method: 'POST',
    });
    instance[callId] = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  downloadInvoiceRequestApi = async (authToken:string) => { 
    const { tripAndBookingId } = this.state;
    const endpoint = `${configJSON.getBookingTypeEndpoint}/${tripAndBookingId}/download_invoice`;
  
    const requestMessage = ApiRequest({
      header: {
        "Content-Type": configJSON.productApiContentType,
        Authorization: `Bearer ${authToken}`,
      },
      endPoint: endpoint,
      method: 'GET',
    });
  
    this.apiCallIdDownloadInvoice = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
/* istanbul ignore next */
  getAvailableSeats = (callId: string, type: string, instance: any) => {
    this.postOrderRequest(callId, 'check_seats', this)
  }
/* istanbul ignore next */
  goToBookingSummary = () => {
    this.postOrderRequest(this.apiCallIdBookingSummary, 'check_booking', this)
    this.props.navigation.navigate("MyBookings")
  }

  gotoEditBooking = (tripId :any , bookingId :any) =>{
    this.props.navigation.navigate("EditBooking", {
      id: tripId,
      bookingId: bookingId,
      editLoader:true
    })
    
  }
  /* istanbul ignore next */
  addReviewRequest = async (values: any) => {
    const { rating, title, desc } = values;
    
  const formData = new FormData();
    // Helper function to append a field and value to formData if the value exists
    const appendFieldIfValueExistss = (field: string, value: any) => {
      if (value) {
        formData.append(field, value);
      }
    };

    
    appendFieldIfValueExistss('title', title);
    appendFieldIfValueExistss('comment', desc);
    appendFieldIfValueExistss('rating', rating);
    appendFieldIfValueExistss('trip_id', this.state.currentTrip_id);

    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
    };
    const endpoint = `${configJSON.addReviewEndpoint}`;

    const requestMessage = ApiRequest({
      header,
      endPoint: endpoint,
      payload: formData,
      method: 'POST',
    });
    this.apiCallIdAddReview = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
/* istanbul ignore next */
  handleAddTocart = async()=>{

    const {trip_id, trip_shcedule_id, noOfPeople} = this.getParamValue();

    const formData =new FormData()
    const appendFieldIfValueExists = (field: string, value: any) => {
      if (value || value ===0) {
          formData.append(field, value);
      }
  };
  appendFieldIfValueExists('trip_id',trip_id );
  appendFieldIfValueExists('trip_schedule_id',trip_shcedule_id );
  appendFieldIfValueExists('quantity',noOfPeople );
  appendFieldIfValueExists('type', 'add_to_cart');

  const authToken = await getAuthToken();
  const header = {
      Authorization: `Bearer ${authToken}`,
  };
  const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.addTocartEndPoint,
      payload:formData,
      method: "POST",
  });
  this.addToCartapiCallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);

  }

   /* istanbul ignore next */
   handleCancelBooking = async(bookingId :any) =>{
    let CancellationReason = "Any"
    const formData = new FormData();
    formData.append("cancellation_reason" , CancellationReason)
    formData.append("order_id" , bookingId)
    const authToken = await getAuthToken();
    const header = {
        Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: 'bx_block_order_management/cancel_order',
      payload:formData,
      method: "POST",
  });
  this.cancelBookingApiCallID = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
  this.setState({
    iscancelBtnClicked : true
  })
   }

/*istanbul ignore next */
 handleUpdateBooking = async()=>{
const {trip_id, trip_shcedule_id, noOfPeople , selectedDate} = this.getParamValue()
const paymentToken =  localStorage.getItem("paymentToken") || ""
const orderID = selectedDate?.existingTrip?.id
const formdata = new FormData();
formdata.append('trip_id', trip_id);
formdata.append('trip_schedule_id', trip_shcedule_id);
formdata.append('quantity', noOfPeople);
formdata.append('type', "update_booking");
formdata.append('card_token', paymentToken);

const authToken = await getAuthToken();
const header = {
  Authorization: `Bearer ${authToken}`,
};
const endpoint = `bx_block_order_management/orders/${orderID}`;

const requestMessage = ApiRequest({
  header,
  endPoint: endpoint,
  payload: formdata,
  method: 'PUT',
});
this.updateBookingApiCallId = requestMessage.messageId;
runEngine.sendMessage(requestMessage.id, requestMessage);

   }




  // Response fucntions
  /* istanbul ignore next */
  getOrderDataResponse = (responseJson: any, errorResponse: any) => {
    if(responseJson?.bookings?.data?.length > 0) {
      this.setState({ bookingsLoader: false, orders: responseJson?.bookings?.data, bookingPagination:responseJson.bookings.page_options });
      this.checkIsExistingTrip()
    } else {
      this.setState({ bookingsLoader: false, orders: [], bookingPagination: {
        total_pages: "",
        per_page: "10",
        current_page: "1",
        total_items: "",
    },});
    }
  }

  getCountsDataResponse = (responseJson: any, errorResponse: any) => {
const remove =  responseJson.removed
    this.setState({orderCount: responseJson, removed:remove})
  }

/* istanbul ignore next */
  getItemDetailsResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ itemDetail: responseJson.data, loading: false })
  }
/* istanbul ignore next */
  rateOrderDataResponse = (responseJson: any, errorResponse: any) => {

    this.setState({ isRateVisible: !this.state.isRateVisible });
    this.props.navigation.goBack();
  }
/* istanbul ignore next */
  getTripDetailsResponse = (responseJson: any, errorResponse: any) => {
    const tripData = responseJson?.trip?.data?.attributes;
    const share_trip = responseJson?.share_trip;
    const trip_schedules = tripData.trip_schedules.data.map((dateItem: any) => ({
      id: dateItem.id,
      date: dateItem.attributes.scheduled_date,
    }));

    this.setState({
      tripDetails: tripData,
      currentTrip_country_id: tripData.country.data.attributes.id,
      currentTrip_type: tripData.trip_type,
      currentTrip_id: responseJson.trip.data.id,
      loading: false,
      trip_schedules: trip_schedules,
      share_trip,
    }, () => this.getOtherTripsWithSingleDetailsPage());
  }
/* istanbul ignore next */
  getOtherTripsWithSingleDetailsPage = () => {
    this.getSimilarTripsRequest();
    this.exploreMoreTripRequest()
  }
/* istanbul ignore next */
  cancelOrderApiResponse = (responseJson: any, errorResponse: any) => {
    this.getOrdersDataRequest();
    this.setState({
      activeOrderId: 0,
      activeItemId: 0,
      isCancelVisible: false,
      cancelDialog: true,
    });
  }
/* istanbul ignore next */
  similarTripListResponse = (responseJson: any, errorReponse: any) => {
    const { currentTrip_id } = this.state;
    const tripDataArray = responseJson.data;
    if (tripDataArray) {
      const filteredTrips = tripDataArray.filter((trip: any) => trip.id !== currentTrip_id);

      this.setState({ similarTrips: filteredTrips })
  }

  }
/* istanbul ignore next */
  exploreTripsResponse = (responseJson: any, errorReponse: any) => {
    const tripDataArray = responseJson.data;
    if (tripDataArray) {
      this.setState({ exploreMoreTrips: tripDataArray })
    }
  }

  /* istanbul ignore next */
  downloadInvoiceResponse = (responseJson: any, errorReponse: any) => {
    const files = responseJson?.file_path;
    if (files.length) {
      this.setState({ pdfUrl: files })
    }
  }
/* istanbul ignore next */
  availableSeatsResponse = (responseJson: any, errorResponse: any) => {
    if(responseJson.errors || errorResponse) {
      const msg = responseJson.errors ?? "Please select available date and people"
      this.setState({seatsDateError: msg})
    } else {
      this.setState({seatsDateError: ""})
    }
  }
/* istanbul ignore next */
  checkBookingResponse = (responseJson: any, errorResponse: any) => {
    // Set state based on API response
   if(responseJson.data) {
    const { pathname } = window.location;
    const { currentTrip_id, selectedDate, noOfPeople , bookednoOfPoeple  , isEdit , bookingDate , existingTrip} = this.state;
    if (pathname.startsWith('/TripDetails/')) {
      this.props.navigation.history.push(`/BookingSummary/${currentTrip_id}/${selectedDate.id}/${noOfPeople}`, {
        selectedDate :selectedDate,
        bookednoOfPoeple :bookednoOfPoeple,
        isEdit :isEdit,
        bookingDate :bookingDate,
        existingTrip : existingTrip
          
      }  )

    }
    this.setState({
      userData: {
        name: responseJson.data.user_details.name,
        email: responseJson.data.user_details.email,
        mobile: responseJson.data.user_details.mobile,
      },
      paymentDetails: {
        tripAmountPerPerson: responseJson.data.payment_details.trip_amount_per_person,
        discountedPricePerPerson: responseJson.data.payment_details.discounted_price_per_person,
        numberOfPeople: responseJson.data.payment_details.number_of_people,
        total: responseJson.data.payment_details.total,
      },
    });
   }
  };

  bookMyTripResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ 
      paymentStatus: !!responseJson?.data?.data, 
      paymentResponse: responseJson 
    }, () => {
      this.gotoPaymentStatusPage()});
  }

  gotoPaymentStatusPage = () => {
    const payload = {
      responseJson: this.state.paymentResponse,
      paymentStatus: this.state.paymentStatus
    }
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "PaymentStatus"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      payload,
    });
  
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }

  handleCancelBookingResponse = (responseJson: any, errorResponse: any) =>{
    if(responseJson.message){
      this.setState({
        isDeleteModalOpen :false,
        isSuccessResponse:true,
        responseStatusModal:true,
        responseMsg: "Your booking has Cancelled", 
      })
    }else{
      this.setState({
        isSuccessResponse:false,
        responseStatusModal:true,
        responseMsg: responseJson.errors, 
        isDeleteModalOpen :false
      })
      
    }
    
  }
  
  handleUpdateBookingResponse= (responseJson: any, errorResponse: any)=>{

/*istanbul ignore next */
    if(responseJson?.data){
      this.setState({
        isSuccess_updateBooking :true,    
        isSuccessResponse:true,
        responseStatusModal:true,
        responseMsg: "Your booking has updated successfully", 
        
      },()=>{
          setTimeout(()=>{
            const payload = {
              responseJson: responseJson,
              paymentStatus: !!responseJson?.data?.data
            }
            const message1: Message = new Message(
              getName(MessageEnum.NavigationMessage)
            );
            message1.addData(
              getName(MessageEnum.NavigationTargetMessage),
              "PaymentStatus"
            );
            message1.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            const raiseMessage1: Message = new Message(
              getName(MessageEnum.NavigationPayLoadMessage)
            );
            raiseMessage1.addData(getName(MessageEnum.SessionResponseData), {
              payload,
            });
          
            message1.addData(
              getName(MessageEnum.NavigationRaiseMessage),
              raiseMessage1
            );
            this.send(message1);
          },1000)
      }
    )
     
     
     
    }else{
      this.setState({
        isSuccessResponse:false,
        responseStatusModal:true,
        responseMsg: responseJson?.errors, 
        isSuccess_updateBooking :false        
      })
    }
  }
  /* istanbul ignore next */
  addReviewResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.data && responseJson.data.type === "review") {
        // Handle success response
        this.setState({
          openReviewModal: false,
          responseStatusModal: true,
          responseMsg: "Review added successfully",
          isSuccessResponse: true,
        })
        // Do something with the review data, such as displaying it to the user
    } else if (responseJson && responseJson.data && responseJson.data.type === "error") {
        // Handle error type response
        const errorMessage = Object.values(responseJson.data.attributes.errors).join(', ');
        this.setState({
          openReviewModal: false,
          responseStatusModal: true,
          responseMsg: capitalizeFirstLetter(errorMessage),
          isSuccessResponse: false,
        })
        // Display the error message to the user
    } else {
        // Unexpected response format
        this.setState({
          openReviewModal: false,
          responseStatusModal: true,
          responseMsg: "Something went wrong",
          isSuccessResponse: false,
        })
    }
};
/* istanbul ignore next */
addToCartResponse = (responseJson: any, errorResponse: any)=>{
if(responseJson && responseJson?.data){
  this.setState({
    cartItems : responseJson.data,
    isSuccessResponse:true,
    responseStatusModal:true,
    addToCartMsg: 'Item Added To cart'
  })
  setTimeout(()=>{location.reload()},1000)
} else {
  this.setState({
    // cartItems : responseJson.data,
    isSuccessResponse:false,
    responseStatusModal:true,
    addToCartMsg: 'Sorry, something went wrong!'
  })
}
}


  // Internal function

  handleOpenReviewModal =  () => {
    const userDataString = localStorage.getItem('userData')
    if(userDataString) {
      this.setState({
        openReviewModal: true,
      })
    } else {
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    
  }

  handleCloseReviewModal = () => {
    this.setState({
      openReviewModal: false,
    })
  }


  toggleExpand = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  }

  handleChange = (value: string) => {
    if (value !== this.state.tab) {
      this.setState({ tab: value });
    }
  };

  closeResponseModal = () => {
    this.setState({
      responseStatusModal: false,
    }, () => {
      if(this.state.isSuccessResponse) {
        this.getTripdetailsRequest(this.state.tripAndBookingId);
        this.getItemDetailDataRequest(this.state.tripAndBookingId)
      }
    })
  }

  handleClose = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      seatsDateError: ""
    })
  }

  handleDecrement = (preference_type: string, min_seat: number) => {
    if (preference_type === 'public') {
      if (this.state.noOfPeople > 0) {
        this.setState({
          
          noOfPeople: this.state.noOfPeople - 1
        })
      }
    } else {
      if (this.state.noOfPeople > min_seat) {
        this.setState(prev => ({
          ...prev,
          noOfPeople: prev.noOfPeople - 1
        }))
      }
    }
    this.debouncedSearch("apiCallIdAvaibleSeats", 'check_seats', this)
  }

  handleIncrement = (preference_type: string, seats: number, max_seat: number) => {
    if (preference_type == 'public') {
      if (this.state.noOfPeople < seats) {
        this.setState(prev => ({
          ...prev,
          noOfPeople: prev.noOfPeople + 1
        }))
      }
    } else {
      if (this.state.noOfPeople < max_seat) {
        this.setState(prev => ({
          ...prev,
          noOfPeople: prev.noOfPeople + 1
        }))
      }
    }
    this.debouncedSearch("apiCallIdAvaibleSeats", 'check_seats', this)
  }
  handleOpenDeleteModal = () =>{
    this.setState({
      isDeleteModalOpen : true
    })
  }
  handleClosemodal = () =>{
    this.setState({
      isDeleteModalOpen : false
    })
  }
/* istanbul ignore next */
  handleChipClick = (value: string) => {
    this.setState({ activeFilter: value,bookingPagination: {
      total_pages: "",
      per_page: "10",
      current_page: "1",
      total_items: "",
  }, },()=> this.getOrdersDataRequest() );
  };
/* istanbul ignore next */
  handleAnchorSelect = (event: React.MouseEvent) => {
    this.setState({ anchorEle: event.currentTarget });
  }
/* istanbul ignore next */
  navigateBookingDetails = (detailId: string | number) => {
    this.props.navigation.navigate("BookingDetails",
      {
        id: detailId,
      })
  }
  /* istanbul ignore next */
  getStatusLabel = (status: string) => {
    switch(status) {
      case "refunded":
        return "Refunded";
      case "cancelled":
        return "Cancelled";
      case "closed":
        return "Completed";
      case "created":
        return "Confirmed";
      case "completed" :
        return "Completed";
      case "payment_captured":
        return "Payment Captured"
      default:
        return "Not Match";       
    }
  }
/* istanbul ignore next */
  handleDateChange = (newDate: any) => {
    const parts = newDate.toString().split('/');
    let formattedDate = '';
    if (parts.length === 3) {
      // Assuming the format is "YYYY/MM/DD"
      const [year, month, day] = parts;
      formattedDate = `${year}-${month}-${day}`;
    }
    const dateId = this.state.trip_schedules.find(item => item.date === formattedDate)
    this.setState({ 
      selectedDate: dateId ?? {id: '1', date: ''}
    }, () =>  this.debouncedSearch("apiCallIdAvaibleSeats", 'check_seats', this))
  };

/* istanbul ignore next */
  debounce<T extends (...args: any[]) => void>(func: T, delay: number): T & { cancel: () => void } {
    let timeout: any;

    const debounced = (...args: any[]) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };


    return debounced as T & { cancel: () => void };
  }
/* istanbul ignore next */
  getParamValue = () => {
    const trip_id = this.props.navigation.getParam("trip_id");
    const trip_shcedule_id = this.props.navigation.getParam("trip_schedule_id");
    const noOfPeople = this.props.navigation.getParam("noOfPeople");
    const selectedDate = {...this.props.navigation.history.location.state}
    return {trip_id, trip_shcedule_id, noOfPeople, selectedDate  }
  }
/* istanbul ignore next */
  handleBookingSummaryActionBtn = (payment_type: string) => {
    const { trip_id, trip_shcedule_id, noOfPeople } = this.getParamValue();
    const isTripInCart = this.context?.cartItems?.filter((item: any) => {
      return item?.attributes?.trip?.id == trip_id;
    });
    let cartId: number = 0
    let cardUrl: string = ""
    if (isTripInCart !== undefined) {
      cartId = isTripInCart[0]?.id
      cardUrl = cartId !== undefined && cartId > 0 ? `/${cartId}` : ""
    }
    if (payment_type === "pay_later") {
      this.postOrderRequest("apiCallIdBookMyTrip", "booking", this)
    } else {
      this.setState({paymentFlow:!this.state.paymentFlow})
    }
  }

  openShareModal = () => {
    this.setState({openShareModal: true})
  }

  closeShareModal = () => {
    this.setState({openShareModal: false})
  }

  goToAdvancedSearch(type: string, params: string, value: string){
    if(type === "travelAgency") {
      this.props?.navigation.navigate("TravelAgencyList");
    } else if(type === "travelBuddy") {
      this.props?.navigation.navigate("TravelBuddyList");
    } else {
      this.goToAdvancedSearchPageWithType(params, value)
    }
  }

  goToAdvancedSearchPageWithType = async (params: string, value: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AdvancedSearch"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    const queryParams = {
      [params]: value
     }
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      queryParams
    });
    message.addData(
      getName(MessageEnum.NavigationRaiseMessage),
      raiseMessage
    );
    this.send(message);
  }
  navigateToProviderDetailsPage = (type: string, id: string) => {
    const myType = type === "travel_buddy" ? "buddy" : "agency";
    this.props.navigation.navigate("ServiceProviderDetails", {
      type: myType,
      id: id,
    })
  }

  formatDate =(dateString :any)=> {
        // Create an array of month names
        const monthNames = [
          "January", "February", "March",
          "April", "May", "June", "July",
          "August", "September", "October",
          "November", "December"
      ];
  /* istanbul ignore next */
    if (!dateString) return null;
    const parts = dateString.split('-');
    const day = parseInt(parts[2], 10);
    const year = parts[0];
    const month = parseInt(parts[1], 10);
    // Get the month name using the month number
    const monthName = monthNames[month - 1];

    // Format the date
    return `${monthName} ${day}, ${year}`;
}
 calculatePayment = (noOfPeople :any, bookednoOfPeople:any, paymentDetails:any, currency:any) => {
  const totalPayment = (noOfPeople - bookednoOfPeople) * paymentDetails.tripAmountPerPerson;
  const discountedAmount = (noOfPeople - bookednoOfPeople) * paymentDetails.discountedPricePerPerson;
  const finalAmount = totalPayment - discountedAmount;
  return `${finalAmount} ${currency}`;
};

calculatePrice(price_per_person: number, validDefinedDiscount: boolean, defineDiscountPercent: number): number {
  if (validDefinedDiscount) {
      return price_per_person - (price_per_person * defineDiscountPercent) / 100;
  } else {
      return price_per_person;
  }
}
/* istanbul ignore next */
downloadPDF = () => {
  fetch(this.state.pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      
      const blobUrl = window.URL.createObjectURL(new Blob([blob]));
     
      const link = document.createElement('a');
      
      link.href = blobUrl;
      
      link.setAttribute('download', 'your_pdf_filename.pdf'); 
      
      document.body.appendChild(link);
      
      link.click();
      
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error downloading PDF: ', error);
    });
};

handleBookingPagination = (event: React.ChangeEvent<unknown>, value: number) => {
  this.setState((prevState) => ({
    bookingPagination: {
          ...prevState.bookingPagination,
          current_page: String(value),
      },
  }), () => this.getOrdersDataRequest());
};
  // Customizable Area End
}
