import React from "react";

// Customizable Area Start
import {
    Typography, Box, Divider, Avatar, Card, Grid, Button
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";
import { cancelationPolicy } from "./assets";
import { primaryColor } from "../../../components/src/theme";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import { agencyLogo, tour1 } from "../../landingpage/src/assets";
import { commomStyles, headingImgBlock } from "./TripDetails.web";
import { muiTheme } from "./MyBookings.web";
import i18next from 'i18next';

// Customizable Area End

import OrdermanagementController, {
    Props
} from "./OrdermanagementController";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import { capitalizeFirstLetter } from "../../../components/src/utils";
import toast from "react-hot-toast";

export default class BookingSummary extends OrdermanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    addedOrRemoved = (noOfPeople: any, bookednoOfPoeple: any) => {

        /* istanbul ignore nxet */
        if (noOfPeople > bookednoOfPoeple) {
            return noOfPeople - bookednoOfPoeple
        } else {
            return bookednoOfPoeple - noOfPeople
        }

    }

/* function if isEdit true */
 /* istanbul ignore next */

    cardDetailsEdit = (list: Record<string, any>, classes: any) => {
        return Object.entries(list).map(([label, value], index) => (
            <Box key={index} display={'flex'} justifyContent={'space-between'} width={'100%'}>

                <Typography className={classes.label}>
                    {(label) === 'Number of People'
                        ? (
                            <>
                                {label}
                                {this.state?.noOfPeople != this.state?.bookednoOfPoeple &&
                                    <span
                                        className={
                                            this.state?.bookednoOfPoeple > this.state?.noOfPeople
                                                ? classes.redText
                                                : classes.greenText
                                        }
                                    >

                                        ({this.state?.bookednoOfPoeple > this.state?.noOfPeople ? '-' : '+'}
                                        {Math.abs(this.addedOrRemoved(this.state?.noOfPeople, this.state?.bookednoOfPoeple))})
                                    </span>
                                }

                            </>
                        )
                        : label
                    }
                </Typography>
                <Typography className={classes.value}>{value}</Typography>
            </Box>
        ))
    }

    cardDetails = (list: Record<string, any>, classes: any) => {
        return Object.entries(list).map(([label, value], index) => (
            <Box key={index} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                <Typography className={classes.label}>{label}</Typography>
                <Typography className={classes.value}>{value}</Typography>
            </Box>
        ))
    }
    conditionShort = (condition: any, truthy: any, falsy: any) => {
        return condition ? truthy : falsy
    }

     bookingDatesError = (bookingDate: any, classes: any) => {
        return (
            (bookingDate !== this.state?.selectedDate?.date) &&
            <Box className={classes.alertWrapper}> You are about to change the date .Your new trip date will be  {this.formatDate(this.state?.selectedDate?.date)}</Box>
        )
    }
     bookingNoOfpeople = (bookednoOfPoeple: any, noOfPeople: any,classes:any,addedAmountMessage:any,refundAmountMessage:any) => {
        return (
            bookednoOfPoeple != this.state?.noOfPeople &&
            <Box className={classes.alertWrapper}> You {(noOfPeople > bookednoOfPoeple) ? 'added' : 'removed'}  {Math.abs(this.addedOrRemoved(noOfPeople, bookednoOfPoeple))} {' '}
                {i18next.t('customer.checkAvailabilityPopup.person')}{Math.abs(this.addedOrRemoved(noOfPeople, bookednoOfPoeple)) !== 1 ? i18next.t('customer.checkAvailabilityPopup.s') : ''}  {i18next.t('customer.checkAvailabilityPopup.toYourTrip')} {`Below ${noOfPeople > bookednoOfPoeple ? addedAmountMessage : refundAmountMessage} `}.
            </Box>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const {userData, paymentDetails, tripDetails, noOfPeople, bookednoOfPoeple, isEdit, bookingDate, addToCartMsg } = this.state;
        const addedAmountMessage = i18next.t('customer.checkAvailabilityPopup.addedAmountToBePaid')
        const refundAmountMessage= i18next.t('customer.checkAvailabilityPopup.amountWillBeRefunded')
        const {
            title = '',
            description = '',
            payment_method = '',
            trip_detail: {
                data: {
                    attributes: {
                        cancellation_policy = '',
                    } = {}, attributes = {} } = {}
            } = {},
            account: {
                data: {
                  attributes: {
                    entity_detail: {
                      data: {
                        attributes: {
                          agency_logo = ''
                        } = {}
                      } = {}
                    } = {}
                  } = {}
                } = {}
              } = {},
              servise_provier_details: {
                name: full_name = '',
                user_type: account_type = ''
              } = {},
            primary_image_url = '', currency = '',
        } = tripDetails

        const userDetailsOraganize = {
            [i18next.t('customer.checkAvailabilityPopup.name')]: userData.name,
            [i18next.t('customer.checkAvailabilityPopup.emailAddress')]: userData.email,
            [i18next.t('customer.checkAvailabilityPopup.mobileNumber')]: '+' + userData.mobile
        }

        const paymentDetailsOraganize = {
            [i18next.t('customer.checkAvailabilityPopup.tripAmountPerPerson')]: paymentDetails.tripAmountPerPerson + ' ' + currency,
            [i18next.t('customer.checkAvailabilityPopup.discountedPrice')]: paymentDetails.discountedPricePerPerson + ' ' + currency,
            [i18next.t('customer.checkAvailabilityPopup.numberOfPeople')]: paymentDetails.numberOfPeople
        }


        /* istanbul ignore next */
        const isTripInCart = this.context?.cartItems?.some((item: any) => {
            const { trip_id } = this.getParamValue();
            return item?.attributes?.trip?.id == trip_id;
        });




    

        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={muiTheme}>
                <Box className={classes.container}>
                    <Breadcrumbs list={[
                        {
                            title: i18next.t('customer.checkAvailabilityPopup.home'),
                            link: '/LandingPage'
                        },
                        {
                            title: i18next.t('customer.checkAvailabilityPopup.tripDetails'),
                            link: '/TripDetails'
                        },
                        {
                            title: i18next.t('customer.checkAvailabilityPopup.reviewDetails')
                        }
                    ]} />
                    <Divider className={classes.dividerMargin} />

                    <Box className={classes.innerContainer}>
                        <Typography className={classes.heading}>
                            {i18next.t('customer.checkAvailabilityPopup.bookingSummary')}
                        </Typography>
                        <Box className={classes.tripDetailWrapper}>
                            <img src={primary_image_url} alt='tripImage' className={classes.tripImage} />
                            <Box>
                                <Typography className={classes.title} gutterBottom>
                                    {title}
                                </Typography>
                                <Typography className={`${classes.description} ${classes.marginBottom16}`}>
                                    {description}
                                </Typography>
                                <Box className={classes.flexStyle16}>
                                <Avatar className={classes.userImage} alt="User Avatar" src={agency_logo} />   
                                <Box>
                                <Typography className={classes.travelAgencyName}>{`${full_name} (${capitalizeFirstLetter(account_type)})`}</Typography>                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {
                            /* istanbul ignore next */
                            isEdit &&
                            <>
                                {this.bookingDatesError(bookingDate,classes)}
                                {this.bookingNoOfpeople(bookednoOfPoeple, noOfPeople,classes,addedAmountMessage,refundAmountMessage)}
                            </>
                        }
                        <Grid container spacing={2}>
                            <Grid item sm={12} md={6}>
                                <Card className={classes.cardWrapper} >
                                    <Typography className={`${classes.fontSize19} ${classes.value}`}>
                                    {i18next.t('customer.checkAvailabilityPopup.userDetails')}
                                    </Typography>
                                    {this.cardDetails(userDetailsOraganize, classes)}
                                </Card>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <Card className={classes.cardWrapper}>
                                    <Typography className={`${classes.fontSize19} ${classes.value}`}>
                                    {i18next.t('customer.checkAvailabilityPopup.paymentDetails')}
                                    </Typography>
                                    {this.conditionShort(isEdit, this.cardDetailsEdit(paymentDetailsOraganize, classes), this.cardDetails(paymentDetailsOraganize, classes))}
                                    <Divider className={classes.marginBottom16} style={{ width: '100%' }} />
                                    
                                    { 
                                    isEdit &&  /* istanbul ignore next */
                                     bookednoOfPoeple !== noOfPeople &&  /* istanbul ignore next */
                                     noOfPeople < bookednoOfPoeple ? ( /* istanbul ignore next */
                                        <span>{i18next.t('customer.checkAvailabilityPopup.amountToBeRefunded')} {paymentDetails.discountedPricePerPerson * this.addedOrRemoved(noOfPeople, bookednoOfPoeple) + ' ' + currency}</span>
                                    ) : (  /* istanbul ignore next */
                                        isEdit && bookednoOfPoeple !== noOfPeople && noOfPeople > bookednoOfPoeple && (
                                            <span>{i18next.t('customer.checkAvailabilityPopup.amountToBePaid')} {paymentDetails.discountedPricePerPerson * this.addedOrRemoved(noOfPeople, bookednoOfPoeple) + ' ' + currency}</span>
                                        )
                                    )}

                                    {this.conditionShort(isEdit, this.cardDetails({ [i18next.t('customer.checkAvailabilityPopup.totalAmount')] : paymentDetails.total+" "+ currency }, classes), this.cardDetails({ [i18next.t('customer.checkAvailabilityPopup.totalAmount')]: `${paymentDetails.total} ${currency}` }, classes))}

                                    {this.conditionShort(!isEdit, <Box className={classes.buttonWrapper}>
                                        {

                                            !isTripInCart &&
                                            <>
                                                <Button className={classes.outlineButton} classes={{ label: classes.buttonLabel }} data-testId="addTocartBtn" onClick={() => this.handleAddTocart()}>
                                                {i18next.t('customer.checkAvailabilityPopup.addToCart')}
                                                </Button>
                                            </>
                                        }
                                        {payment_method &&
                                        <Button
                                            data-testid="action-button"
                                            onClick={() => this.handleBookingSummaryActionBtn(payment_method)}
                                            className={classes.filledButton} classes={{ label: classes.filledbtnLabel }}>
                                            {payment_method === "pay_later" ? i18next.t('customer.checkAvailabilityPopup.bookMyTrip') : i18next.t('customer.checkAvailabilityPopup.payNow')}
                                        </Button>
                                        }
                                       
                                    </Box>,
                                        this.conditionShort(
                                            noOfPeople !== bookednoOfPoeple,
                                            noOfPeople > bookednoOfPoeple ? (
                                                <Button
                                                    data-testid="actionCustomer"
                                                    className={classes.filledButtonedit}
                                                    classes={{ label: classes.filledbtnLabel }}
                                                    onClick={
                                                     /* istanbul ignore next */
                                                    () => this.handleBookingSummaryActionBtn(payment_method)}
                                                     >
                                                    {payment_method === "pay_later" ? i18next.t('customer.checkAvailabilityPopup.bookMyTrip') : i18next.t('customer.checkAvailabilityPopup.payNow')}
                                                </Button>
                                            ) : (
                                                <Button
                                                    data-testid="handleUpdateBooking"
                                                    className={classes.filledButtonedit}
                                                    classes={{ label: classes.filledbtnLabel }}
                                                    onClick={
                                                        /* istanbul ignore next */
                                                        () => this.handleUpdateBooking()}
                                                >
                                                    {i18next.t('customer.checkAvailabilityPopup.confirmBooking')}
                                                </Button>
                                            ),
                                            null
                                        )
                                    )}
                                   
                                </Card>
                            </Grid>
                        </Grid>

                        <Divider style={{ margin: '56px 0 32px' }} />

                        {headingImgBlock(classes, cancelationPolicy, i18next.t('customer.checkAvailabilityPopup.cancellationPolicyNoShow'))}

                        <Typography className={classes.detailDescription}>
                            {cancellation_policy}
                        </Typography>

                        <ResponseStatusModal
                            open={this.state?.responseStatusModal}
                            onClose={() => this.setState({ responseStatusModal: false })}
                            message={this.state?.responseMsg}
                            isSuccess={this.state?.isSuccess_updateBooking}
                        />

                    </Box>
                </Box>
                
                <ResponseStatusModal
                    isSuccess={this.state.isSuccessResponse}
                    message={addToCartMsg || this.state.responseMsg}
                    open={this.state.responseStatusModal}
                    onClose={this.closeResponseModal}
                />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const bookingSummaryStyles = (theme: Theme) => ({
    innerContainer: {
        width: '65.9%',
        margin: '0 auto',
    },
    heading: {
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '120%',
        marginBottom: '39px'
    },
    flexStyle16: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
    },
    title: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '130%',
        opacity: 0.9
    },
    description: {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '160%',
        opacity: 0.8,
    },
    cardWrapper: {
        display: 'flex',
        padding: '32px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '24px',
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        width: '100%',
        boxShadow: 'none',
        height: 'min-content'
    },
    fontSize19: {
        fontSize: '19px',
    },
    tripImage: {
        width: '190px',
        height: '190px'
    },
    label: {
        color: 'rgba(33, 36, 38, 0.80)',
        lineHeight: '130%'
    },
    value: {
        fontWeight: 500,
        lineHeight: '130%',
    },
    detailDescription: {
        lineHeight: '180%',
        opacity: 0.75
    },
    outlineButton: {
        display: 'flex',
        width: '166px',
        padding: '12px 48px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '49px',
        border: '1px solid #FE7F2D',
        background: '#FFF'
    },
    buttonLabel: {
        color: primaryColor,
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        whiteSpace: 'nowrap' as const,
        textTransform: 'none' as const
    },
    filledButton: {
        display: 'flex',
        width: '165px',
        padding: '12px 48px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '49px',
        background: primaryColor,
        '&:hover': {
            background: primaryColor
        }
    },
    filledButtonedit: {
        display: 'flex',
        width: '24vw',
        padding: '12px 48px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '49px',
        background: primaryColor,
        '&:hover': {
            background: primaryColor
        }
    },
    filledbtnLabel: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        whiteSpace: 'nowrap' as const,
        textTransform: 'none' as const
    },
    alertWrapper: {
        background: '#FEF3C6',
        minHeight: '50px',
        color: '#C7984E',
        borderLeft: '6px solid #D07923',
        lineHeight: '50px',
        paddingLeft: '10px',
        marginBottom: '30px',
        borderRadius: '10px'
    },
    greenText: {
        color: 'green'
    },
    redText: {
        color: 'red'
    },
    dividerMargin: {
        margin: '48px 0'
    },
    tripDetailWrapper: {
        display: 'flex',
        flexDirection: 'row' as const,
        gridColumnGap: '32px',
        marginBottom: '40px'
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'row' as const,
        gridColumnGap: '16px',
    },
    [theme.breakpoints.between(768, 1024)]: {
        heading: {
            fontSize: '28px',
            fontWeight: 600,
        },
    },
    [theme.breakpoints.down(768)]: {
        innerContainer: {
            width: '100%',
            margin: '0 auto',
        },
        heading: {
            fontSize: '24px',
            fontWeight: 500,
            marginBottom: '30px'
        },
        dividerMargin: {
            margin: '32px 0'
        },
        tripDetailWrapper: {
            display: 'flex',
            flexDirection: 'column' as const,
            marginBottom: '20px',
            gap: '20px'
        },
        buttonWrapper: {
            display: 'flex',
            flexDirection: 'column' as const,
            gap: '16px',
            width: '100%'
        },
        tripImage: {
            width: '100%',
            height: 'auto'
        },
        title: {
            fontSize: '24px',
            fontWeight: 500,
        },
        filledButton: {
            width: '100%',
        },
        outlineButton: {
            width: '100%'
        },
        filledButtonedit: {
            width: '100%'
        }
    },
    ...commomStyles(theme)
})

const BookingSummaryWeb = withStyles(bookingSummaryStyles)(BookingSummary)
export { BookingSummaryWeb }
// Customizable Area End
