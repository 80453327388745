import React from "react";

// Customizable Area Start
import {
    Box, Divider, Typography
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider, createTheme
} from "@material-ui/core/styles";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import Loader from "../../../components/src/Loader.web";
import i18next from 'i18next';

const themeAU = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        },
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});


// Customizable Area End

import ContentManagementController, {
    Props
} from "./ContentManagementController";


export default class AboutUs extends ContentManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            loader,
           aboutus
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={themeAU}>
                 {loader ? (
                    <Box style={{ minHeight: '80vh' }}>
                        <Loader loading={loader} />
                    </Box>
             ) : 
                <Box className={classes.containerAU} style={{direction:"ltr"}}>
                    <Breadcrumbs list={[
                        {
                            title: i18next.t('aboutUs.home'),
                            link: '/LandingPage'
                        },
                        {
                            title: i18next.t('aboutUs.aboutUs'),
                        },
                    ]} />
                    <Divider style={{ margin: '48px 0' }} />

                    <Box className={classes.innerContainerAU}>
                        <Typography className={classes.headingAU}>
                        {i18next.t('aboutUs.beena')}
                        </Typography>
                        {
                            aboutus.length > 0 ?
                            aboutus.map((value: Record<string, any>, i) => (
                                <Box key={value.id}>
                                    <Typography>{value.title}</Typography>
                                    <Box dangerouslySetInnerHTML={{ __html: value.content }} />
                                </Box>
                            ))
                            : <Typography className={classes.descriptionAU}>No data found</Typography>
                        }
                    </Box>
                </Box>
    }

            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = (theme: Theme) => ({
    containerAU: {
        margin: '49px auto 120px',
        padding: '0 26px 0 38px',
        width: '100%'
    },
    innerContainerAU: {
        width: '75.9%',
        margin: '0 auto',
    },
    headingAU: {
        fontStyle: 'normal',
        fontSize: '40px',
        lineHeight: '120%',
        marginBottom: '39px',
        fontWeight: 700,
    },
    descriptionAU: {
        lineHeight: '160%',
        fontSize: '16px',
        opacity: 0.8,
        height: '25vh',
        fontWeight: 400,
        textAlign: 'center' as const,
    },
})

const AboutUsWeb = withStyles(styles)(AboutUs)
export { AboutUsWeb }
// Customizable Area End
