import React, { useEffect, useMemo, useState } from 'react';
import {
  AppBar, Toolbar, Grid, Avatar, Badge, IconButton,
  FormControlLabel, Typography, Button, Box, styled,
  Radio, RadioGroup, Popover, List, ListItem, Drawer
} from '@material-ui/core';
import {
  makeStyles, createStyles
} from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { KeyboardArrowDown, Menu } from "@material-ui/icons";
import cart from "./cart.png";
import logo from "./sawahLogo.png";
import language from "./language.png";
import CheckIcon from './checkMark.js';
import Divider from './Divider.js';
import ChatIcon from "./chatIcon.js";
import arrowDown from "./arrowDown.svg";
import whatsapp from "./whatsappHeader.png";
import { getStorageData, removeStorageData } from '../../framework/src/Utilities';
import { useWindowWidth } from './utils';
import './Navbar.css'
import Sidebar from './Sidebar';
import { useAuth } from '../../blocks/user-profile-basic/src/AuthContext.web';
import i18n from 'i18next';
import i18next from 'i18next';


import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import {getAuthToken}  from "../../components/src/utils";
import { setStorageData } from "../../framework/src/Utilities";


const options = [
  { value: 'en', label: 'English' },
  { value: 'ar', label: "عربي" },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    grow: { flexGrow: 1, },
    headerTop: {
      maxWidth: '560px',
      width: '100%',
      height: '36px',
      background: '#ECECEC'
    },
    logo: { cursor: 'pointer', width: 'auto', height: '93px' },
    title: {
      display: 'block',
    },
    main_header: {
      boxShadow: 'none',
      height: 'auto',
      backgroundColor: '#fff'
    },
    mainHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '30.5px 24px 62.5px 0',
    },
    navRight: {
      gap: '55.51px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    notification: { cursor: 'pointer' },
    language: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      cursor: 'pointer',
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '16px',
      letterSpacing: '0.3px',
      '&.topbar': {
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '150%'
      }
    },
    profile: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
      fontWeight: 600,
      cursor: 'pointer',
    },
    profileBadge: {
      '&.MuiAvatar-root': { width: '49.5px', height: '49.5px' },
      '&.customer': {
        width: '32px',
        height: '32px',
        borderRadius: '32px',
        border: '0.5px solid #D9D9D9',
      }
    },
    languageSelect: { minWidth: '110px', color: 'black' },
    radioLabel: {
      color: '#212426',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '24px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
    },
    LanguagePopover: {
      borderRadius: '8px',
      background: '#FFF',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
      maxWidth: '196px',
      width: '100%',
      padding: '16px',
      marginTop: '20px'
    },
    langLabel: {
      padding: 0,
      gap: '14.8px',
      color: '#212426',
      fontSize: '16px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '24px',
      cursor: 'pointer'
    },
    langList: { padding: 0, display: 'flex', gap: '20px', flexDirection: 'column' },
    divider: { margin: '16px 0' },
    langTitle: {
      color: '#212426',
      fontSize: '16px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: '27px',
    },
    profileItem: {
      padding: '8px 16px',
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '150%',
      cursor: 'pointer',
      '&.listitem': {
        fontSize: '16px',
        lineHeight: '24px'
      }
    },
    userName: {
      color: '#212426',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 'normal',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      maxWidth: '200px'
    },
    userNameWrapper: {
      gap: '8px',
      color: '#212426',
      display: 'flex',
      fontSize: '16px',
      alignItems: 'center',
    },
    profileMenu: {
      display: 'flex', flexDirection: 'column',
      padding: '8px 0px',
      alignItems: 'flex-start',
      gap: '4px'
    },
    color: {
      '&.Mui-checked': { color: '#FE7F2D' }
    },
    activeLang: { width: '16px' },
    rootLabel: { gap: '12px', margin: '16px 0 0 -2px' },
    radioRoot: { padding: 0 },
    image: {
      height: '24px',
      width: '24px'
    },
    button: {
      backgroundColor: '#FE7F2D',
      color: '#FFF',
      textTransform: 'initial',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontStyle: 'normal',
      '&:hover': {
        backgroundColor: '#FE7F2D',
      }
    },
    toolBar: {
      minHeight: '93px'
    },
    popOver: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      borderRadius: '8px',
      background: '#FFF',
      boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.25)',
    },
    profilePopOverCustomer: {
      width: '189px',
      marginTop: '16px',
    },
    profilePopOver: {
      width: '212px',
      height: '144px',
      marginTop: '6.5px',
    },
    visibleXs: {
      display: 'none'
    },
    hiddenXs: {
      display: 'flex',
      padding: '0 72px 0 61px'
    },
    headerRight: {
      display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '56px'
    },
    buttonRoot: {
      borderRadius: '27px',
      width: '177px',
      height: '54px',
      fontSize: '19px',
    },
    logoVisible: {
      display: 'none'
    },
    menu: {
      display: 'none'
    },
    hiddeninXs: {
      display: 'flex', alignItems: 'center',
      gap: '12px', cursor: 'pointer'
    },
    [theme.breakpoints.between(561.05, 949.05)]: {
      mainHeader: {
        padding: '0 48px 62.5px 36px',
        justifyContent: 'space-between'
      },
      navRight: {
        gap: '24px',
        justifyContent: 'flex-end',
      },
      toolBar: { minHeight: '72px' },
      logo: { width: '92.903px', height: '72px' },
      hiddenXs: { padding: '0 48px 0 36px' },
      headerRight: {
        justifyContent: 'flex-end',
        '&.customer': {
          gap: '32px'
        },
        '&.guest': {
          gap: '24px'
        }
      },
      language: {
        fontSize: 0,
        gap: '2px',
        letterSpacing: 0,
        '&.topbar': {
          fontSize: 0,
          gap: '2px',
          letterSpacing: 0,
          '& svg': {
            width: '16px',
            height: '16px'
          }
        }
      },
      title: { display: 'none' },
      buttonRoot: {
        display: 'inline-flex',
        height: '44px',
        padding: '10px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        width: '99px',
        fontSize: '16px',
        lineHeight: '24px',
        whiteSpace: 'nowrap',
        marginLeft: '16px'
      },
      profile: {
        '&.mt-root8': {
          marginLeft: '16px'
        }
      },
      profileBadge: {
        '&.MuiAvatar-root': {
          width: '32px',
          height: '32px',
          borderRadius: '32px',
          border: '0.5px solid #D9D9D9',
        }
      },
      userName: {
        fontWeight: 500,
        lineHeight: 'normal',
        maxWidth: '181px'
      },
      userNameWrapper: {
        gap: '16px'
      },
      logoVisible: {
        width: '92.903px',
        height: '72px',
        display: 'block',
        cursor: 'pointer'
      },
      menu: {
        width: '32px',
        height: '50px',
        display: 'block'
      }
    },
    [theme.breakpoints.down(560.05)]: {
      image: {
        height: '20px',
        width: '20px'
      },
      logo: { width: '82.583px', height: '64px' },
      toolBar: {
        minHeight: '100px'
      },
      profilePopOverCustomer: { width: '159px' },
      profileItem: {
        padding: '4px 8px',
        fontSize: '12px',
        '&.listitem': {
          fontSize: '12px',
          lineHeight: '150%'
        }
      },
      visibleXs: {
        display: 'flex'
      },
      hiddenXs: {
        display: 'none'
      },
      buttonRoot: {
        display: 'inline-flex',
        height: '32px',
        padding: '6px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '6px',
        flexShrink: '0',
        fontSize: '16px',
        borderRadius: '31px',
        lineHeight: '24px',
        width: '83px',
        whiteSpace: 'nowrap'
      },
      mainHeader: {
        padding: '0 16px 46.5px 8px',
        justifyContent: 'space-between'
      },
      logoVisible: {
        width: '82.583px',
        height: '64px',
        display: 'block',
        cursor: 'pointer'
      },
      navRight: {
        gap: '28px'
      },
      profilePopOver: {
        width: '162px',
        height: 'auto'
      },
      language: {
        fontSize: '12px',
        gap: '4px',
        letterSpacing: '0.09px',
        '&.topbar': {
          display: 'none'
        }
      },
      hiddeninXs: { display: 'none' },
      menu: {
        width: '35px',
        height: '45px',
        display: 'block'
      },
      profile: { gap: '4px' },
      userName: {
        fontSize: 0,
      },
      userNameWrapper: {
        gap: 0
      },
      profileBadge: {
        '&.MuiAvatar-root': {
          width: '24px',
          height: '24px',
          borderRadius: '24px',
          border: '0.375px solid #D9D9D9',
        }
      }
    }
  }),
);

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#02AF72',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    [theme.breakpoints.down(768.05)]: {
      width: '8px',
      height: '8px',
    }
  },
}));

const TopNav = ({ history, location: { pathname }, isLoggedIn = false, account_type = '', handleChangePassword }) => {
  const classes = useStyles();
  const [isNameUpdate, setIsNameUpdate] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorLang, setAnchorLang] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [userData, setUserData] = useState('')
  const [open, setState] = useState(false);
  const windowWidth = useWindowWidth();
  const {currentUser, handleLogOut , cartItems} = useAuth();
  let apiCallIdGetCurrentUser = React.useRef("");


  const isMobile = windowWidth < 768.05;



  const receive = async (from, message) => {
    
    // Response handler mapping
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === apiCallIdGetCurrentUser.current) {
        if(responseJson)
          {
            const subscripitonPlansget = await getStorageData("subscriptionPlan")
            if (subscripitonPlansget !== "false") {
             window.location.reload();
            }
          }
      }

    }
};

useEffect(() => {
  const subscribedMessages = [
    getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.SessionResponseMessage),
    getName(MessageEnum.CountryCodeMessage),
    getName(MessageEnum.RestAPIResponceDataMessage)
  ];
  
  runEngine.attachBuildingBlock({ receive }, subscribedMessages);
  
    return () => {
    };
  }, []);

  useEffect(() => {
    const handleUserNameUpdate = ({ detail }) => {
      setIsNameUpdate(detail);
    };
   // i18n.changeLanguage('ar');
    const fetchLanguage = async () => {
      const defaultState = await getStorageData("language", false);
      const defaultLanguage = defaultState === "english" ? 'en' : 'ar';
      setSelectedLanguage(defaultLanguage);
      i18n.changeLanguage(defaultLanguage);
      document.documentElement.dir = defaultLanguage === 'ar' ? 'rtl' : 'ltr';

    };
  
    document.addEventListener('userNameUpdate', handleUserNameUpdate);
    fetchLanguage();
   
    const isLanguageSet = localStorage.getItem("languageSet");
    if (!isLanguageSet) {
      const setLanguageAndReload = async () => {
        const defaultState = await getStorageData("language", false);
        await getUserProfileData(defaultState);
        setStorageData("languageSet", "true");
      };
  
      setLanguageAndReload();
    }
  
    return () => {
      document.removeEventListener('userNameUpdate', handleUserNameUpdate);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    });
  
    return () => {
    };
  }, [pathname]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await getStorageData('userData', true);
        setUserData(response);
      } catch (error) {
        console.error(error);
      }
    };
  
    fetchUserData();
  
    return () => {
    };
  }, [isLoggedIn, isNameUpdate]);

  const userName = useMemo(() => {
    if (userData) {
      if (account_type === 'customer') {
        return `${i18next.t('headerFooter.hi')}, ${userData.first_name}`
      } else if (account_type && account_type !== '') {
        return `${userData.first_name} ${userData.last_name}`
      }
    }
  }, [userData, account_type])

  const goToLoginPage = () => {
    history.push("/EmailAccountLoginBlock")
  }

  const goToMyProfile = () => {
    history.push('/MyProfile')
    closePopover()
  }

  const goToMyProfileTrip = () => {
    history.push('/MyProfileTrip')
    closePopover()
  }
  

  const goToCartPage = () => {
    if (isLoggedIn) {
      history.push('/Cart')
    } else {
      history.push('/EmailAccountLoginBlock')
    }
  }

  const gotoMyBookings = () => {
    history.push('/MyBookings')
    closePopover()
  }

  const gotoChangePassword = () => {
    handleChangePassword(true);
    closePopover()
  }

  const closePopover = () => {
    setAnchorEl(null);
  }

  const handleLangClick = (event) => {
    setAnchorLang(event.currentTarget);
  };

  const closeLangPopover = () => {
    setAnchorLang(null);
  };

  const handleLangChange = (event) => {
   // event.stopPropagation();
    const prevLanguage = selectedLanguage === "en" ? "english" :"arabic"; 
    setStorageData("prevLanguage", prevLanguage); 
    setSelectedLanguage(event.target.value);
    const langValue= event.target.value === "en" ? "english" :"arabic"
    getUserProfileData(langValue);
    i18n.changeLanguage(`${event.target.value}`);
    setStorageData("language", langValue);
    i18n.changeLanguage(`${event.target.value}`)
    .then(async () => {
      document.documentElement.dir = 'rtl' ;
    })
    .catch((err) => {
      console.log('something went wrong while applying RTL', err);
    });
   
    closeLangPopover()
  };

  const getUserProfileData = async (selectedLanguage) => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${authToken}`, 
    };
    const data = {
      "app_language_name": selectedLanguage,
    };
  
    const endUrl = "bx_block_language_options/languages/set_app_language";
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    apiCallIdGetCurrentUser.current = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/+966558217033`;

    window.open(whatsappUrl, '_blank');
  };

  const goToChatCustomer = () => {
    if (isLoggedIn) {
      history.push('/ChatCustomer')
    } else {
      history.push('/EmailAccountLoginBlock')
    }
  }

  const goToLandingPage = () => {
    history.push('/LandingPage')
  }

  const goToDashboard = () => {
    history.push('/Dashboard')
  }

  const handleSignOut = async () => {
    await removeStorageData('authToken');
    await removeStorageData('userData');
    await removeStorageData('account_type');
    await removeStorageData('rememberMe');
    await removeStorageData('subscriptionPlan');
    await removeStorageData('languageSet');
    // await removeStorageData('i18nextLng');
    // await removeStorageData('language');
    sessionStorage.removeItem('authToken');
    handleLogOut();
    closePopover();
    document.dispatchEvent(new CustomEvent("isLoggedIn", { detail: { loggedin: false, account_type: '' } }));
    history.push('/EmailAccountLoginBlock')
    window.location.reload()
  }

  const toggleDrawer = (open) => {
    setState(open);
  };

  const popoverProfile = useMemo(() => {
    const profileItemClass = `${classes.profileItem} ${account_type !== 'customer' ? 'listitem' : ''}`
    return <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={closePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      classes={{
        paper: `${classes.popOver} ${account_type !== 'customer' ?
          classes.profilePopOver : classes.profilePopOverCustomer}`
      }}
    >
      <Box className={classes.profileMenu}>
        <Typography className={profileItemClass} onClick={account_type === 'customer'? goToMyProfile:goToMyProfileTrip}>{i18next.t('headerFooter.myProfile')}</Typography>
        {account_type === 'customer' &&
          <Typography className={profileItemClass} onClick={gotoMyBookings}>{i18next.t('headerFooter.myBookings')}</Typography>}
        <Typography className={profileItemClass} onClick={gotoChangePassword}>{i18next.t('headerFooter.changePassword')}</Typography>
        <Typography className={profileItemClass} style={{ color: 'F00' }} onClick={handleSignOut}>
        {i18next.t('headerFooter.logOut')}
        </Typography>
      </Box>
    </Popover>
  }, [anchorEl])

  const profile = (isCustomer = false) => {
    return <>
      <Box className={`${classes.profile} ${isCustomer ? 'mt-root8' : ''}`}
        onClick={e => {
          setAnchorEl(e.currentTarget);
        }}>
        {isCustomer
          ? <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
          >
            <Avatar alt="Remy Sharp" src={currentUser.profile_image} className={classes.profileBadge} />
          </StyledBadge>
          : <Avatar alt="Remy Sharp" src={currentUser.profile_image} className={`${classes.profileBadge} customer`} />
        }
        <Typography component={'span'} className={classes.userNameWrapper}>
          <Typography component={'span'} className={classes.userName}>
            {userName}
          </Typography>
          <KeyboardArrowDown />
        </Typography>
      </Box>
    </>
  }

  const languageDropdown = (isCustomer = false) => {
    return <>
      <Box className={`${classes.language} ${isCustomer ? 'topbar' : ''}`} onClick={handleLangClick}>
        <img src={language} alt="alert" className={classes.image} />
        {selectedLanguage === 'en' ? 'English' : i18next.t('headerFooter.arabic')}
        {isMobile
          ? <KeyboardArrowDown style={{ width: '16px', height: '16px' }} />
          : <img src={arrowDown} alt="down" style={{ width: '13.5px', height: '6.75px' }} />}
      </Box>
      <Popover
        open={Boolean(anchorLang)}
        anchorEl={anchorLang}
        onClose={closeLangPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.LanguagePopover
        }}
      >
        <List className={classes.langList}>
          {options.map((option, index) => {
            return (
              <ListItem key={index} className={classes.langLabel}
              onClick={() => handleLangChange({ target: { value: option.value } })}
              >
                <div className={classes.activeLang}>
                  {selectedLanguage === option.value && <CheckIcon />}
                </div>
                {option.label}
              </ListItem>
            )
          })}
        </List>
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.langTitle} >Default Language</Typography>
        <RadioGroup
          name="language"
          aria-label="language"
          defaultValue={selectedLanguage}
          onChange={handleLangChange}
        >
          {options.map((option, index) => {
            return (
              <FormControlLabel
                key={index}
                name='language'
                value={option.value}
                label={option.label}
                classes={{ label: classes.radioLabel, root: classes.rootLabel }}
                control={<Radio classes={{
                  checked: option.value ,
                  colorSecondary: classes.color,
                  root: classes.radioRoot
                }} />}
              />
            )
          })}
        </RadioGroup>
      </Popover>
    </>
  }

 

  return ((isLoggedIn && account_type !== 'customer')
    ? <>
      <Drawer

        anchor="left" //from which side the drawer slides in

        variant="temporary" //if and how easily the drawer can be closed

        open={open} //if open is true, drawer is shown

        onClose={() => toggleDrawer(false)} //function that is called when the drawer should close

        onOpen={() => toggleDrawer(true)} //function that is called when the drawer should open
      >

        <Box style={{ width: '292px', padding: '0 24px' }}>
          <Sidebar isDrawer={true} handleClose={toggleDrawer} />
        </Box>
      </Drawer>
      {/* <Box className={classes.notification}>
        <NotificationIcon active />
      </Box> */}
      <Box className={`${classes.headerTop} ${classes.visibleXs}`} display={'flex'} padding={'0 16px'}
        justifyContent={'flex-end'} alignItems={'center'} gridGap={'28px'}>
        {languageDropdown()}
        <img
          onClick={() => handleWhatsAppClick()}
          style={{cursor: 'pointer'}}
          src={whatsapp}
          alt="whatsapp"
          className={classes.image} />
      </Box>
      <header className={classes.mainHeader}>
        <img src={logo} alt="LOGO" className={classes.logoVisible}
          onClick={goToDashboard} />
        <Box className={classes.navRight}>
          {languageDropdown(true)}
          <Box 
          onClick={() => handleWhatsAppClick()}
          style={{cursor: 'pointer'}}
          className={classes.hiddeninXs}>
            <img src={whatsapp} alt="alert" className={classes.image} />
            <Typography className={classes.title} component={'span'} style={{
              color: '#212426',
              fontFamily: 'Inter',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '150%',
            }} >
            {i18next.t('headerFooter.whatsapp')}
            </Typography>
          </Box>
          {profile(true)}
          {popoverProfile}
          {/* <Menu className={classes.menu} /> */}
          <IconButton 
          edge="start"
          color="inherit"
          aria-label="open drawer"
          className={classes.menu}
          onClick={() => toggleDrawer(true)}
          // sx={{ mr: 2, display: { xs: 'block', sm: 'none',}, }}
          >   
          <Menu />
        </IconButton>
        </Box>
      </header>
    </>
    : <>
      <AppBar position="fixed" className={classes.main_header}>
        <Box className={`${classes.headerTop} ${classes.visibleXs}`} display={'flex'} padding={'0 16px'}
          justifyContent={'flex-end'} alignItems={'center'} gridGap={'28px'}>
          {languageDropdown()}
          <img 
          onClick={() => handleWhatsAppClick()}
          style={{cursor: 'pointer'}}
          src={whatsapp} alt="whatsapp" className={classes.image} />
        </Box>
        <Box display={'flex'} justifyContent={'space-between'} padding={'0 16px 0 8px'} alignItems={'center'}
          className={classes.visibleXs}>
          <img src={logo} alt="LOGO" className={classes.logo} onClick={goToLandingPage} />
          {isLoggedIn
            ? <Box display={'flex'} gridGap={'28px'} alignItems={'center'}
              style={{ cursor: 'pointer' }}>
              <ChatIcon />
              <img src={cart} alt="alert" className={classes.image} onClick={goToCartPage} />
              <Box display={'flex'} gridGap={'4px'} alignItems={'center'}
                onClick={e => {
                  setAnchorEl(e.currentTarget);
                }}>
                <Avatar alt="Remy Sharp" src={currentUser.profile_image} style={{
                  width: '24px',
                  height: '24px',
                  borderRadius: '24px',
                  border: '0.375px solid #D9D9D9',
                }} />
                <KeyboardArrowDown style={{ width: '16px', height: '16px', color: '#212426' }} />
              </Box>
            </Box>
            : <Box display={'flex'} gridGap={'28px'} alignItems={'center'}
              style={{ cursor: 'pointer' }}>
              {/* <img src={cart} alt="alert" className={classes.image} onClick={goToCartPage} /> */}
              <Button
      className={`${classes.button} ${classes.buttonRoot}`}
      onClick={() => goToLoginPage()}
    >
      {i18next.t('loginBlock.signIn')}
    </Button>
            </Box>
          }
        </Box>

        <Grid container justifyContent="space-between" className={classes.hiddenXs}>
          <Grid item xs={2} sm={3} md={4} >
            <img src={logo} alt="LOGO" className={classes.logo} onClick={goToLandingPage} />
          </Grid>
          <Grid item xs={10} sm={9} md={8}
            className={`${classes.headerRight} ${isLoggedIn ? 'customer' : 'guest'}`}>
            {languageDropdown()}
            <Box 
            onClick={() => handleWhatsAppClick()}
            style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}>
              <img src={whatsapp} alt="whatsapp" className={classes.image} />
              <Typography className={classes.title} component={'span'} style={{
                color: '#212426',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '0.3px'
              }} >
              {i18next.t('headerFooter.whatsapp')}
              </Typography>
            </Box>
            {/* <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={alerts} alt="alert" className={classes.image} />
              <Typography className={classes.title} component={'span'} style={{
                color: '#212426',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '0.3px'
              }} >
                Alerts
              </Typography>
            </Box>
            */}
            { isLoggedIn && account_type === 'customer' &&
              <Box style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }}
              onClick={goToChatCustomer}
              >
              <ChatIcon />
              <Typography className={classes.title} component={'span'} noWrap style={{
                color: '#212426',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '0.3px'
              }} >
                {i18next.t('headerFooter.chats')}
              </Typography>
            </Box>
            }
            {  account_type === 'customer' &&
            <Box style={{ display: 'flex', alignItems: 'center', gap: '8px', cursor: 'pointer' }} onClick={goToCartPage}>
            <Badge badgeContent={cartItems?.length} color="primary">
            <img src={cart} alt="alert" className={classes.image} />
            </Badge>
              <Typography className={classes.title} component={'span'}  style={{
                color: '#212426',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '0.3px'
              }} >
                 {i18next.t('headerFooter.cart')}
              </Typography>
            </Box>
}

            {isLoggedIn
              ? profile()
              : <Button
              className={`${classes.button} ${classes.buttonRoot}`}
              onClick={() => goToLoginPage()}
            >
              {i18next.t('loginBlock.signIn')}
            </Button>
            }
          </Grid>
        </Grid>
        {popoverProfile}
        {/* <Toolbar className={classes.toolBar} >
        
        </Toolbar> */}
      </AppBar>
      <Toolbar className={classes.toolBar} />
    </>
  );
}

export default withRouter(TopNav);
